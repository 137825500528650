import { CREATE_COMPANY_REQUEST,
    CREATE_COMPANY_SUCCESS,
    CREATE_COMPANY_FAILURE } from '../../actions/company/constants';

const INITIAL_STATE = {
    creating: false,
    error: null
}
export default function create(state = INITIAL_STATE, action) {
    switch(action.type) {
        case CREATE_COMPANY_REQUEST:
            return {
                ...state,
                creating: true
            }
        case CREATE_COMPANY_SUCCESS:
            return {
                ...state,
                creating: false
            }
        case CREATE_COMPANY_FAILURE:
            return {
                ...state,
                error: true,
                creating: false,
                errorMsg: action.error
            }
        default:
            return state;
    }
}
// GET BATCHES
export const GET_COLLAB_BATCHES_REQUEST = 'GET_COLLAB_BATCHES_REQUEST';
export const GET_COLLAB_BATCHES_SUCCESS = 'GET_COLLAB_BATCHES_SUCCESS';
export const GET_COLLAB_BATCHES_FAILURE = 'GET_COLLAB_BATCHES_FAILURE';
export const RESET_COLLAB_BATCHES = 'RESET_COLLAB_BATCHES';

// GET BATCHES COLOR
export const GET_BATCHES_COLOR_REQUEST = 'GET_BATCHES_COLOR_REQUEST';
export const GET_BATCHES_COLOR_SUCCESS = 'GET_BATCHES_COLOR_SUCCESS';
export const GET_BATCHES_COLOR_FAILURE = 'GET_BATCHES_COLOR_FAILURE';
export const RESET_BATCHES_COLOR = 'RESET_BATCHES_COLOR';

// GET USER BATCHES
export const GET_COLLAB_USER_BATCHES_REQUEST = 'GET_COLLAB_USER_BATCHES_REQUEST';
export const GET_COLLAB_USER_BATCHES_SUCCESS = 'GET_COLLAB_USER_BATCHES_SUCCESS';
export const GET_COLLAB_USER_BATCHES_FAILURE = 'GET_COLLAB_USER_BATCHES_FAILURE';
export const RESET_COLLAB_USER_BATCHES = 'RESET_COLLAB_USER_BATCHES';
export const TOGGLE_COLLAB_USER_MODAL = 'TOGGLE_COLLAB_USER_MODAL';

// GET BATCH APPLICANTS
export const GET_PR_PRODUCT_APPLICANTS_REQUEST = 'GET_PR_PRODUCT_APPLICANTS_REQUEST';
export const GET_PR_PRODUCT_APPLICANTS_SUCCESS = 'GET_PR_PRODUCT_APPLICANTS_SUCCESS';
export const GET_PR_PRODUCT_APPLICANTS_FAILURE = 'GET_PR_PRODUCT_APPLICANTS_FAILURE';
// RESET PR BATCH APPLICANT
export const RESET_PR_PRODUCT_APPLICANTS = 'RESET_PR_PRODUCT_APPLICANTS';

// CREATE OR UPDATE BATCH
export const CREATE_OR_EDIT_PR_PRODUCT_REQUEST = 'CREATE_OR_EDIT_PR_PRODUCT_REQUEST';
export const CREATE_OR_EDIT_PR_PRODUCT_SUCCESS = 'CREATE_OR_EDIT_PR_PRODUCT_SUCCESS';
export const CREATE_OR_EDIT_PR_PRODUCT_FAILURE = 'CREATE_OR_EDIT_PR_PRODUCT_FAILURE';

// ASSIGN A PRODUCT TO USER
export const ASSIGN_USER_A_PRODUCT_REQUEST = 'ASSIGN_USER_A_PRODUCT_REQUEST';
export const ASSIGN_USER_A_PRODUCT_SUCCESS = 'ASSIGN_USER_A_PRODUCT_SUCCESS';
export const ASSIGN_USER_A_PRODUCT_FAILURE = 'ASSIGN_USER_A_PRODUCT_FAILURE';

// PR PRODUCT ROLLBACK APPLICANT
export const PR_PRODUCT_ROLLBACK_APPLICANT_REQUEST = 'PR_PRODUCT_ROLLBACK_APPLICANT_REQUEST';
export const PR_PRODUCT_ROLLBACK_APPLICANT_SUCCESS = 'PR_PRODUCT_ROLLBACK_APPLICANT_SUCCESS';
export const PR_PRODUCT_ROLLBACK_APPLICANT_FAILURE = 'PR_PRODUCT_ROLLBACK_APPLICANT_FAILURE';

// PR PRODUCT REMOVE APPLICANT
export const PR_PRODUCT_REMOVE_APPLICANT_REQUEST = 'PR_PRODUCT_REMOVE_APPLICANT_REQUEST';
export const PR_PRODUCT_REMOVE_APPLICANT_SUCCESS = 'PR_PRODUCT_REMOVE_APPLICANT_SUCCESS';
export const PR_PRODUCT_REMOVE_APPLICANT_FAILURE = 'PR_PRODUCT_REMOVE_APPLICANT_FAILURE';

// PR Product applicant document verification
export const PR_PRODUCT_APPLICANT_DOCUMENT_VERIFICATION_REQUEST =
  'PR_PRODUCT_APPLICANT_DOCUMENT_VERIFICATION_REQUEST';
export const PR_PRODUCT_APPLICANT_DOCUMENT_VERIFICATION_SUCCESS =
  'PR_PRODUCT_APPLICANT_DOCUMENT_VERIFICATION_SUCCESS';
export const PR_PRODUCT_APPLICANT_DOCUMENT_VERIFICATION_FAILURE =
  'PR_PRODUCT_APPLICANT_DOCUMENT_VERIFICATION_FAILURE';



export const GET_BATCH_DEMOGRAPHY_SUCCESS = 'GET_BATCH_DEMOGRAPHY_SUCCESS';


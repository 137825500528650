import {
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  GET_PROFILE_BY_ID_REQUEST,
  GET_PROFILE_BY_ID_SUCCESS,
  GET_PROFILE_BY_ID_FAILURE,
  GET_TRAINING_DATA_REQUEST,
  GET_TRAINING_DATA_SUCCESS,
  GET_TRAINING_DATA_FAILURE,
  GET_PROFILE_PHOTO_REQUEST,
  GET_PROFILE_PHOTO_SUCCESS,
  GET_PROFILE_PHOTO_FAILURE,
  GET_PROFILE_ACTIVITY_REQUEST,
  GET_PROFILE_ACTIVITY_SUCCESS,
  GET_PROFILE_ACTIVITY_FAILURE,
  RESET_PROFILE_PAGE,
  RESET_PROFILE_PHOTOS,
  SET_PROFILE_PHOTO_DATA,
} from "./constants";
import axios from "axios";
import {
  onRequest,
  onError,
  onSuccess,
  getResponseData,
} from "../../utils/helper";
import { PROFILE_IMAGE_REMOVE_SUCSESS, COVER_IMAGE_REMOVE_SUCSESS } from "./constants";
import { getBaseUrl, ProfileUrls, profileLinks } from "../../utils/links";
import { REMOVE_COMPANY_IMAGE_SUCCESS, REMOVE_COMPANY_COVER_IMAGE_SUCCESS } from "./constants";
import { showToast } from "../../components/create/utils";
import get from 'lodash/get';
let CancelToken = axios.CancelToken;

export let getProfileIdByHandleCancelToken = undefined;
export function getProfileIdByHandle(token, handle) {
  return (dispatch) => {
    if (getProfileIdByHandleCancelToken) getProfileIdByHandleCancelToken();
    dispatch(onRequest(GET_PROFILE_REQUEST));
    let url = `${getBaseUrl()}${ProfileUrls.getUserProfile(handle)}`,
      options = {
        method: "GET",
        url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: new CancelToken(
          (c) => (getProfileIdByHandleCancelToken = c)
        ),
      };
    return axios(options)
      .then((response) => {
        let data =
          response.data && response.data.data ? response.data.data : null;
        if (data) {
          dispatch(onSuccess(GET_PROFILE_SUCCESS, data));
          return data;
        } else {
          let err = response.errors;
          dispatch(onError(GET_PROFILE_FAILURE, err));
        }
        return false;
      })
      .catch((err) => {
        dispatch(onError(GET_PROFILE_FAILURE, err));
        if (axios.isCancel(err)) {
          return {
            cancelled: true,
          };
        } else {
          return false;
        }
      });
  };
}

export let getProfileDataCancelToken = undefined;
export function getProfileData(token, id) {
  return (dispatch) => {
    if (getProfileDataCancelToken) getProfileDataCancelToken();
    dispatch(onRequest(GET_PROFILE_BY_ID_REQUEST));
    let url = `${getBaseUrl()}${ProfileUrls.getUserProfile(null, id)}`,
      options = {
        method: "GET",
        url,
        ...(token && {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
        cancelToken: new CancelToken((c) => (getProfileDataCancelToken = c)),
      };
    return axios(options)
      .then((response) => {
        let data =
          response.data && response.data.data ? response.data.data : null;
        if (data) {
          dispatch(onSuccess(GET_PROFILE_BY_ID_SUCCESS, data));
          return data;
        } else {
          let err = response.errors;
          dispatch(onError(GET_PROFILE_BY_ID_FAILURE, err));
        }
      })
      .catch((err) => {
        dispatch(onError(GET_PROFILE_BY_ID_FAILURE, err));
        if (axios.isCancel(err)) {
          return {
            cancelled: true,
          };
        } else {
          return false;
        }
      });
  };
}

export let getTrainingDataCancelToken = undefined;
export function getTrainingData(token, id) {
  return (dispatch) => {
    if (getTrainingDataCancelToken) getTrainingDataCancelToken();
    dispatch(onRequest(GET_TRAINING_DATA_REQUEST));
    let url = `${getBaseUrl()}${ProfileUrls.training(id)}`,
      options = {
        method: "GET",
        url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: new CancelToken((c) => (getTrainingDataCancelToken = c)),
      };
    return axios(options)
      .then((response) => {
        let data =
          response.data && response.data.data ? response.data.data : null;
        if (data) {
          dispatch(onSuccess(GET_TRAINING_DATA_SUCCESS, data));
          return data;
        } else {
          let err = response.errors;
          dispatch(onError(GET_TRAINING_DATA_FAILURE, err));
        }
      })
      .catch((err) => {
        dispatch(onError(GET_TRAINING_DATA_FAILURE, err));
      });
  };
}

export let getPhotosDataCancelToken = undefined;
export function getPhotosData(token, id, page = 1) {
  return (dispatch) => {
    if (getPhotosDataCancelToken) getPhotosDataCancelToken();
    dispatch(onRequest(GET_PROFILE_PHOTO_REQUEST));
    let url = `${getBaseUrl()}${ProfileUrls.photos(id)}`,
      options = {
        method: "GET",
        url,
        params: {
          page,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: new CancelToken((c) => (getPhotosDataCancelToken = c)),
      };
    return axios(options)
      .then((response) => {
        let data =
          response.data && response.data.data ? response.data.data : null;
        if (data) {
          dispatch(onSuccess(GET_PROFILE_PHOTO_SUCCESS, data));
          return data;
        } else {
          let err = response.errors;
          dispatch(onError(GET_PROFILE_PHOTO_FAILURE, err));
        }
      })
      .catch((err) => {
        dispatch(onError(GET_PROFILE_PHOTO_FAILURE, err));
      });
  };
}

export const setProfilePhotosDataFromState = (data) =>
  onSuccess(SET_PROFILE_PHOTO_DATA, data);

export const resetProfilePhotos = () => onSuccess(RESET_PROFILE_PHOTOS);

export let getFeedDataCancelToken = undefined;
export function getActivity(page = 1, token, id) {
  return (dispatch) => {
    if (getFeedDataCancelToken) getFeedDataCancelToken();
    dispatch(onRequest(GET_PROFILE_ACTIVITY_REQUEST));
    let url = `${getBaseUrl()}${profileLinks.getFeed(id)}`,
      options = {
        method: "GET",
        url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: new CancelToken((c) => (getFeedDataCancelToken = c)),
      };
    return axios(options)
      .then((response) => {
        let data =
          response.data && response.data.data ? response.data.data : null;
        if (data) {
          dispatch(onSuccess(GET_PROFILE_ACTIVITY_SUCCESS, data));
          return data;
        } else {
          let err = response.errors;
          dispatch(onError(GET_PROFILE_ACTIVITY_FAILURE, err));
        }
      })
      .catch((err) => {
        dispatch(onError(GET_PROFILE_ACTIVITY_FAILURE, err));
      });
  };
}

export const resetProfileView = () => ({ type: RESET_PROFILE_PAGE });

// resend verification link
export const emailVerify = (token) => {
  let url = `${getBaseUrl()}${profileLinks.emailVerify}`,
    options = {
      url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  return axios(options)
    .then((response) => {
      return getResponseData(response);
    })
    .catch((_) => {
      return false;
    });
};

export let getReviewCountCancelToken = undefined;
export const getReviewCount = (token, profileId) => {
  if (getReviewCountCancelToken) getReviewCountCancelToken();
  let url = `${getBaseUrl()}${ProfileUrls.reviewCount(profileId)}`,
    options = {
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: new CancelToken((c) => (getReviewCountCancelToken = c)),
    };
  return axios(options)
    .then((response) => {
      let successData = getResponseData(response);
      return successData;
    })
    .catch((_) => false);
};

export let getReviewsListCancelToken = undefined;
export const getReviewsList = (token, profileId, isPublic, params) => {
  if (getReviewsListCancelToken) getReviewsListCancelToken();
  let url = `${getBaseUrl()}${ProfileUrls.reviewsList(profileId, isPublic)}`,
    options = {
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
      cancelToken: new CancelToken((c) => (getReviewsListCancelToken = c)),
    };
  return axios(options)
    .then((response) => {
      let successData = getResponseData(response);
      return successData;
    })
    .catch((_) => false);
};

export const removePicture = (token, profileId, pic) => {
  return (dispatch) => {
    // if (getReviewsListCancelToken) getReviewsListCancelToken();
    let url = `${getBaseUrl()}${ProfileUrls.removePicture(profileId)}`,
      image = pic === "profilepic" ? "remove_image" : "remove_hero_image",
      options = {
        url,
        data: {
          "_method": "PATCH",
          [`${image}`]: 1
        },
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    return axios(options)
      .then((response) => {
        dispatch(onSuccess(pic === "profilepic" ? PROFILE_IMAGE_REMOVE_SUCSESS : COVER_IMAGE_REMOVE_SUCSESS));
        return response;
      })
      .catch((_) => false);
  };
}

export const removeCompanyPicture = (token, companyId, profileId, pic) => {
  return (dispatch) => {
    let url = `${getBaseUrl()}${ProfileUrls.removeCompanyLogo(profileId, companyId)}`,
      image = pic === "logopic" ? "remove_logo" : "remove_hero_image",
      options = {
        url,
        data: {
          "_method": "PATCH",
          [`${image}`]: 1
        },
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    return axios(options)
      .then((response) => {
        dispatch(onSuccess(pic === "logopic" ? REMOVE_COMPANY_IMAGE_SUCCESS : REMOVE_COMPANY_COVER_IMAGE_SUCCESS));
        return response;
      })
      .catch((_) => false);
  };
};
export const blockUserProfile = (token, id, type, user_type) => {
  let url = `${getBaseUrl()}${ProfileUrls.blockProfileLink(user_type, id)}`;
  let blockType = user_type === "profile" ? "block_profile" : "block_company";
  let options = {
    url,
    data: {
      [`${blockType}`]: type
    },
    method: "POST",
    headers: {
      'Authorization': `Bearer ${token}`
    },
  };
  return axios(options)
    .then(response => {
      const data = get(response, ["data", "errors", "status"], null)
      if (!data) {
        if (type == true) {
          showToast(user_type === "profile" ? "blockedprofile" : "blockedcompany", "", token, id, { unblockProfile: blockUserProfile.bind(this, token, id, !type, user_type) })
        }
        if (type == false) {
          showToast(user_type === "profile" ? "unblockedprofile" : "unblockedcompany")
        }
      }
      return response;
    })
    .catch(error => {
      return false
    })


}

import {
    GET_LOGGED_IN_USER_REQUEST,
    GET_LOGGED_IN_USER_SUCCESS,
    GET_LOGGED_IN_USER_FAILURE
} from '../actions/constants';
import {
    PROFILE_UPDATE_SUCCESS,
    FOLLOW_USER_SUCCESS,
    UNFOLLOW_USER_SUCCESS,
    ADD_ADDRESS_REQUEST,
    UPDATE_SHIPPING_ADDRESS_SUCCESS,
    DELETE_SHIPPING_ADDRESS_SUCCESS,
    ADD_ADDRESS_SUCCESS,
    UPDATE_ALLERGENS_SUCCESS,
    OTP_VERIFY_SUCCESS,
    GET_PREMIUM_COMPANIES_SUCCESS,
    RESET_PHONE_NUMBER
} from '../actions/profile/constants';
import {
    FOLLOW_COMPANY_SUCCESS,
    UNFOLLOW_COMPANY_SUCCESS,
    CREATE_COMPANY_SUCCESS,
    DELETE_COMPANY_SUCCESS
} from '../actions/company/constants';
import {
    UPDATE_PROFILE_UNREAD_NOTIFICATION_COUNT,
    UPDATE_PROFILE_UNREAD_CHAT_NOTIFICATION_COUNT,
    MARK_NOTIFICATIONS_SEEN_SUCCESS,
    MARK_CHAT_NOTIFICATIONS_SEEN_SUCCESS,
    MARK_ALL_NOTIFICATIONS_READ_SUCCESS
} from '../actions/notifications/constants';
import { LOGOUT_REQUEST } from '../actions/login/constants';
import { DONATION_PREFERENCE_SUCCESS, UPLOAD_DOCUMENTS_SUCCESS } from '../actions/common/constants';
import { PROFILE_IMAGE_REMOVE_SUCSESS } from '../actions/profile/constants';
import { getProfileItemsToShow, setPromptDataInLocalStorage } from '../utils/sessionHelper';

const INITIAL_STATE = {
    user: {},
    hasFetched: false,
    premiumCompanies: {
        companies: null,
        hasFetched: false
    }
}

export default function user(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_LOGGED_IN_USER_REQUEST:
            return {
                ...state,
                fetching: true,
                hasFetched: false
            }
        case GET_LOGGED_IN_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                hasFetched: true,
                fetching: false
            }
        case GET_LOGGED_IN_USER_FAILURE:
            return {
                ...state,
                error: true,
                errorMsg: action.error,
                fetching: false
            }
        case PROFILE_UPDATE_SUCCESS:
            const session = JSON.parse(localStorage.getItem("session")) || null;
            const profileItemsToBeFilled = getProfileItemsToShow(action.payload);
            session.promptToShowIndex = session.promptToShowIndex === profileItemsToBeFilled.length
                ? 0 : session.promptToShowIndex;
            setPromptDataInLocalStorage(session);
            return {
                ...state,
                user: {
                    ...state.user,
                    profile: { ...action.payload, longProfileLoaded: true }
                }
            }
        case RESET_PHONE_NUMBER:
            return {
                ...state,
                user: {
                    ...state.user,
                    profile: {
                        ...state.user.profile,
                        verified_phone: 0
                    }
                }
            }
        case OTP_VERIFY_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    profile: {
                        ...state.user.profile,
                        verified_phone: 1
                    }
                }
            }
        case UPLOAD_DOCUMENTS_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    profile: {
                        ...state.user.profile,
                        document_meta: {
                            images: action.payload.images
                        }
                    }
                }
            }
        case UPDATE_PROFILE_UNREAD_NOTIFICATION_COUNT:
            return {
                ...state,
                user: state.user
                    && state.user.profile
                    ? {
                        ...state.user,
                        profile: {
                            ...state.user.profile,
                            notificationCount: parseInt(state.user.profile.notificationCount) + 1
                        }
                    }
                    : state.user
            }
        case UPDATE_PROFILE_UNREAD_CHAT_NOTIFICATION_COUNT:
            return {
                ...state,
                user: state.user
                    && state.user.profile
                    ? {
                        ...state.user,
                        profile: {
                            ...state.user.profile,
                            messageCount: parseInt(state.user.profile.messageCount) + 1
                        }
                    }
                    : {}
            }
        case ADD_ADDRESS_REQUEST:
            return {
                ...state,
                fetching: true
            }
        case ADD_ADDRESS_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    profile: {
                        ...state.user.profile,
                        shippingaddress: [...state.user.profile.shippingaddress, action.payload]
                    }
                }
            }
        case UPDATE_SHIPPING_ADDRESS_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    profile: {
                        ...state.user.profile,
                        shippingaddress: state.user.profile.shippingaddress.map(_ => {
                            if (_.id !== action.payload.id) {
                                return _;
                            }
                            return action.payload
                        })
                    }
                }
            }
        case DELETE_SHIPPING_ADDRESS_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    profile: {
                        ...state.user.profile,
                        shippingaddress: state.user.profile.shippingaddress.filter(_ => _.id !== action.payload)
                    }
                }
            }
        case MARK_NOTIFICATIONS_SEEN_SUCCESS:
        case MARK_ALL_NOTIFICATIONS_READ_SUCCESS:
            return {
                ...state,
                user: state.user
                    && state.user.profile
                    ? {
                        ...state.user,
                        profile: {
                            ...state.user.profile,
                            notificationCount: 0
                        }
                    }
                    : {}
            }
        case MARK_CHAT_NOTIFICATIONS_SEEN_SUCCESS:
            return {
                ...state,
                user: state.user
                    && state.user.profile
                    ? {
                        ...state.user,
                        profile: {
                            ...state.user.profile,
                            messageCount: 0
                        }
                    }
                    : {}
            }
        case FOLLOW_COMPANY_SUCCESS:
        case FOLLOW_USER_SUCCESS:
            return {
                ...state,
                user: state.user
                    && state.user.profile
                    ? {
                        ...state.user,
                        profile: {
                            ...state.user.profile,
                            followingProfiles: state.user.profile.followingProfiles
                                ? {
                                    count: state.user.profile.followingProfiles.count + 1
                                }
                                : { count: 1 }
                        }
                    }
                    : state.user
            }
        case UNFOLLOW_COMPANY_SUCCESS:
        case UNFOLLOW_USER_SUCCESS:
            return {
                ...state,
                user: state.user
                    && state.user.profile
                    ? {
                        ...state.user,
                        profile: {
                            ...state.user.profile,
                            followingProfiles: state.user.profile.followingProfiles
                                ? {
                                    count: state.user.profile.followingProfiles.count - 1
                                }
                                : { count: 0 }
                        }
                    }
                    : state.user
            }
        case UPDATE_ALLERGENS_SUCCESS:
            return {
                ...state,
                user: state.user
                    && state.user.profile
                    ? {
                        ...state.user,
                        profile: {
                            ...state.user.profile,
                            allergens: action.payload
                        }
                    }
                    : state.user
            }
        case DONATION_PREFERENCE_SUCCESS:
            return {
                ...state,
                user: state.user
                    && state.user.profile
                    ? {
                        ...state.user,
                        profile: {
                            ...state.user.profile,
                            is_donation: action.payload.is_donation,
                            donation_organisation: action.payload.donation_organisation
                        }
                    }
                    : state.user
            }
        case GET_PREMIUM_COMPANIES_SUCCESS:
            return {
                ...state,
                premiumCompanies: {
                    ...state.premiumCompanies,
                    companies: action.payload,
                    hasFetched: true
                }
            }
        case CREATE_COMPANY_SUCCESS:
            return {
                ...state,
                user: state.hasFetched
                    ? {
                        ...state.user,
                        companies: state.user.companies
                            && !!state.user.companies.length
                            ? [action.payload, ...state.user.companies]
                            : [action.payload]
                    }
                    : state.user
            }
        case DELETE_COMPANY_SUCCESS:
            return {
                ...state,
                user: state.hasFetched
                    ? {
                        ...state.user,
                        companies: state.user.companies.filter(_ => _.id !== action.payload.companyId)
                    }
                    : state.user
            }
        case PROFILE_IMAGE_REMOVE_SUCSESS:
            return {
                ...state, user: {
                    ...state.user, profile: {
                        ...state.user.profile,
                        image_meta: null
                    }
                }

            }
        case LOGOUT_REQUEST:
            return INITIAL_STATE
        default:
            return state;
    }
}
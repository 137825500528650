import { PREVIEW_DETAILS_FAILURE, PREVIEW_DETAILS_REQUEST, PREVIEW_DETAILS_SUCCESS, PREVIEW_USER_FAILURE, PREVIEW_USER_SUCCESS, RESET_PREVIEW_DETAILS } from "../../actions/collaborations/constants"

const INITIAL_STATE = {
    isLoading: false,
    tempToken: "",
    isOwner: false,
    previewDetails: {},
    error: null
}

export default function previewAuth(state = INITIAL_STATE, action) {
    switch (action.type) {
        case PREVIEW_USER_SUCCESS:
            return {
                ...state,
                ...action.payload,
            }
        case PREVIEW_DETAILS_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case PREVIEW_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                previewDetails: action.payload
            }
        case PREVIEW_DETAILS_FAILURE:
            return {
                ...INITIAL_STATE,
                error: action.errors
            }
        case PREVIEW_USER_FAILURE:
            return {
                tempToken: null,
                error: action.errors
            }
        case RESET_PREVIEW_DETAILS:
            return INITIAL_STATE
        default:
            return state
    }
}
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { showToast } from "../create/utils";
import NotificationPopUP from "../NotificationsPopUp";

function TopBar(props) {
    const { profile, isMobile, handleNotificationClick, showNotificationBar, currentPage } = props;
    const [topBarData, setTopBarData] = useState(props.topBarData);
    const history = useHistory();

    const displayUnreadCountTypes = ['chat', 'notification'];

    const topBarTypesResponse = {
        passbook:profile && profile.payment && profile.payment.earning > 0 ? '/passbook' : '/paid-taster-program',
        chat: '/messenger',
        notification: '/notifications'
    }

    const getCurrentPagesLinkMatch = (type) => {
        if (!Object.keys(topBarTypesResponse).includes(type)) return '/';
        return topBarTypesResponse[type]
    }

    const handleLinkClick = (type) => {
        setTopBarData((preTopBarData) => {
            return [...preTopBarData.map((preTopBarDataItem) => {
                if (preTopBarDataItem.type == type) {
                    return { ...preTopBarDataItem, unread: false }
                } else {
                    return preTopBarDataItem
                }
            })]
        })
        if (type === "notification") {
            if(isMobile){
                history.push(getCurrentPagesLinkMatch(type));
            }else{
                handleNotificationClick();
            }
        }
        else if(type === "passbook") {
            if(profile && profile.is_paid_taster && profile.payment.earning===0){
                showToast("noPassBookTransactions","",false,"passbook-toast","")
            }else{
                history.push(getCurrentPagesLinkMatch(type));
            }
        }else{
            history.push(getCurrentPagesLinkMatch(type));
        }
    }

    useEffect(() => {
        setTopBarData(props.topBarData);
    }, [props.topBarData]);

    return (
        <>
            <ul className="top-bar__content__user-links__container__list">
                {topBarData && topBarData.map((item, index) => {
                    const { unread_icon, read_icon, type, unread, count } = item;
                    return (
                        <li
                            key={`landing-top-bar-item-${type}`} className={`cursor-pointer top-bar__content__user-links__container__list--item ${type}-tour ${currentPage == getCurrentPagesLinkMatch(type) ? 'active' : ''}`}
                            onClick={() => handleLinkClick(type)}>
                            <div className="item-content">
                                {unread && !displayUnreadCountTypes.includes(type) ? <img src={unread_icon} alt={`unread-icon-${type}`} /> : !displayUnreadCountTypes.includes(type) && <img src={read_icon} alt={`read-icon-${type}`} />}
                                {displayUnreadCountTypes.includes(type) && <img src={read_icon} alt={`read-icon-${type}`} />}
                                {unread && displayUnreadCountTypes.includes(type) && <span className={`unread-count ${String(count).length > 2 ? 'large' : ''}`}>{count}</span>}
                            </div>
                        </li>
                    )
                })}
                {showNotificationBar && (
                    <div className="top-bar__popup">
                        <div className="top-bar__popup__header">
                            <p className="top-bar__popup__header__title">
                                Notifications
                                {profile && profile.notificationCount
                                    ? `• ${profile.notificationCount}`
                                    : ''}
                            </p>
                            <Link className="top-bar__popup__header__link" to="/notifications">
                                SEE ALL
                            </Link>
                        </div>
                        <NotificationPopUP />
                    </div>
                )}
            </ul>

        </>
    )
}

export default TopBar;
import {
  GET_LOGGED_IN_USER_REQUEST,
  GET_LOGGED_IN_USER_SUCCESS,
  GET_LOGGED_IN_USER_FAILURE,
} from '../constants';
import { onRequest, onSuccess, onError, getResponseData } from '../../utils/helper';
import { getBaseUrl, ProfileUrls, loggedOutUrls } from '../../utils/links';
import Axios from 'axios';
const CancelToken = Axios.CancelToken;

export const getCurrentUser = (authToken) => {
  return (dispatch) => {
    dispatch(onRequest(GET_LOGGED_IN_USER_REQUEST));
    let url = `${getBaseUrl()}${ProfileUrls.profile()}`,
      options = {
        url,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
    return Axios(options)
      .then((response) => {
        let data = response && response.data && response.data.profile ? response.data : false;
        if (data) {
          dispatch(onSuccess(GET_LOGGED_IN_USER_SUCCESS, { ...data, profile: { ...data.profile, longProfileLoaded: true } }));
        } else {
          dispatch(onError(GET_LOGGED_IN_USER_FAILURE, response.data));
        }
        return data;
      })
      .catch((error) => {
        dispatch(onError(GET_LOGGED_IN_USER_FAILURE, error));
        return false;
      });
  };
};

// getUser short_profile
export const getShortProfileCurrentUser = (authToken) => {
  return (dispatch) => {
    dispatch(onRequest(GET_LOGGED_IN_USER_REQUEST));
    let url = `${getBaseUrl()}${ProfileUrls.shortProfile}`,
      options = {
        url,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
    return Axios(options)
      .then((response) => {
        let data = response && response.data && response.data.profile ? response.data : false;
        if (data) {
          dispatch(onSuccess(GET_LOGGED_IN_USER_SUCCESS, data));
        } else {
          dispatch(onError(GET_LOGGED_IN_USER_FAILURE, response.data));
        }
        return data;
      })
      .catch((error) => {
        dispatch(onError(GET_LOGGED_IN_USER_FAILURE, error));
        return false;
      });
  };
};

// email verify
export let emailVerifyCancelToken = undefined;
export const emailVerify = (code, token) => {
  if (emailVerifyCancelToken) emailVerifyCancelToken();
  let url = `${getBaseUrl()}${loggedOutUrls.emailVerify(code)}`,
    options = {
      url,
      ...(token && {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      cancelToken: new CancelToken((c) => (emailVerifyCancelToken = c)),
    };
  return Axios(options)
    .then((response) => {
      let successData = getResponseData(response);
      return successData;
    })
    .catch((_) => false);
};

import { gaEvents, gaInvitationsEvents } from "../../../../utils/events";
import { INVITATIONS_GA } from "./constants";

export function getSelectedInviteeCities(inviteeList) {
    if (!inviteeList || !inviteeList.size) return [];
    const countMap = new Map();

    for (const [key, value] of inviteeList) {
        const city = value.city;

        if (countMap.has(city)) {
            const cityInfo = countMap.get(city);
            cityInfo.count += 1;
            countMap.set(city, cityInfo); // Update the entry with the incremented count
        } else {
            countMap.set(city, { id: key, city, count: 1 });
        }
    }

    const cityCountsArray = Array.from(countMap.values());
    return cityCountsArray;
};

// schedule details pill type text
export function getTypeIdFromText(type) {
    if (!type) return false;
    let typeId = {
        'accepted': 1,
        'pending': 2,
        'declined': 3,
        'invited': false,
    };
    return typeId[type];
}

export const handleInvitation = (data) => {
    const { event, action, label } = data;
    if (!event || !action || !label) return false;
    gaEvents.schedule.invite(action, label, event);
};

//Get empty screen text for invitee list 
export function getEmptyText(type) {
    let text;
    switch (type) {
        case "accepted":
            text = "No Accepted Applicants"
            break;
        case "pending":
            text = "No Pending Applicants"
            break;
        case "declined":
            text = "No Declined Applicants"
            break;
        default:
            text = "No Invited Applicants"
    }
    return text;
}
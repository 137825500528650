import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CloseIcon from '../../../assets/images/common/modals/close.svg';
import { checkValidEmails } from '../../../utils/helper';
import { withRouter } from 'react-router';
import { createCompany,
    createCompanyCancelToken } from '../../../actions/company/create';
import { companiesLink } from '../../../utils/internalLinks';

const texts = {
    title: 'Create a Company',
    name: 'Eg. Mukta Foods Private Limited',
    email: 'contact@muktafoods.com',
    invalidEmailError: 'Please enter valid email.'
}

class CreateCompanyModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            invalidEmail: false,
            disabledForm: false
        }
        this._handleInputChange = this._handleInputChange.bind(this);
        this._handleCompanyCreate = this._handleCompanyCreate.bind(this);
        this._toggleDisableForm = this._toggleDisableForm.bind(this);
    }

    _handleCompanyCreate(e) {
        e.preventDefault();
        this.setState({
            invalidEmail: false
        })
        const { email, name } = this.state;
        if (!email.trim().length || !name.trim().length) return;
        if (!checkValidEmails(email)) {
            this.setState({
                invalidEmail: true
            })
            return;
        }
        const { user: { user: { profile } },
            token,
            createCompany,
            history,
            onClose } = this.props;
        let dataToSend = {
            email,
            name
        }
        this._toggleDisableForm();
        createCompany(profile.id,
            dataToSend,
            token)
            .then(val => {
                this._toggleDisableForm();
                if (val.company_id) {
                    onClose();
                    history.push(companiesLink.view(val.company_id));
                }
            })
    }

    _handleInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentWillUnmount() {
        if (createCompanyCancelToken) createCompanyCancelToken();
    }

    _toggleDisableForm(disabled) {
        this.setState({
            disabledForm: typeof disabled !== 'undefined'
                ? disabled
                : !this.state.disabledForm
        })
    }

    render() {
        const { onClose,
            company: { create } } = this.props,
            { email, name } = this.state,
            isDisabled = ((!email.trim().length
                        || !name.trim().length)
                        || (email && !checkValidEmails(email)));
        return (<>
            <div className="default-modal__header">
                <p className="default-modal__header__title">{texts.title}</p>
                <button className="default-modal__header__close" onClick={onClose}>
                    <img src={CloseIcon} alt="close modal" />
                </button>
            </div>
            <div className="default-modal__content">
                <form onSubmit={this._handleCompanyCreate}>
                    <div className="profile-form-modals__form">
                        <div className="profile-form-modals__form__group">
                            <label className="profile-form-modals__label">Company Name</label>
                            <input type="text"
                                name="name"
                                value={name}
                                autoFocus={true}
                                onChange={this._handleInputChange}
                                className="profile-form-modals__input"
                                required
                                placeholder={texts.name} />
                        </div>
                        <div className="profile-form-modals__form__group">
                            <label className="profile-form-modals__label">Company Email</label>
                            <input type="email"
                                name="email"
                                value={email}
                                onChange={this._handleInputChange}
                                required
                                className="profile-form-modals__input"
                                placeholder={texts.email} />
                            { this.state.invalidEmail && <p className="error-text">{texts.invalidEmailError}</p> }
                            { create
                                && create.error
                                && <p className="error-text">{create.errorMsg}</p> }
                        </div>
                    </div>
                    <div className="profile-form-modals__footer profile-form-modals__footer--right">
                        <button type="submit"
                            disabled={isDisabled}
                            className={`profile-form-modals__btn
                            ${isDisabled
                                ? 'profile-form-modals__btn--disabled'
                                : ''}`}>Create</button>
                    </div>
                </form>
            </div>
        </>)
    }
}

function mapStateToProps(state) {
    return {
        token: state.login.token,
        user: state.user,
        company: state.companyReducer
    }
}

CreateCompanyModal.propTypes = {
    onClose: PropTypes.func
}

const mapDisptachToProps = {
    createCompany
}

export default withRouter(connect(mapStateToProps, mapDisptachToProps)(CreateCompanyModal));

// create company request
export const CREATE_COMPANY_REQUEST = 'CREATE_COMPANY_REQUEST'
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS'
export const CREATE_COMPANY_FAILURE = 'CREATE_COMPANY_FAILURE'

// company view
export const GET_COMPANY_DETAILS_REQUEST = 'GET_COMPANY_DETAILS_REQUEST';
export const GET_COMPANY_DETAILS_SUCCESS = 'GET_COMPANY_DETAILS_SUCCESS';
export const GET_COMPANY_DETAILS_FAILURE = 'GET_COMPANY_DETAILS_FAILURE';

// company types
export const GET_COMPANY_TYPES_REQUEST = 'GET_COMPANY_TYPES_REQUEST';
export const GET_COMPANY_TYPES_SUCCESS = 'GET_COMPANY_TYPES_SUCCESS';
export const GET_COMPANY_TYPES_FAILURE = 'GET_COMPANY_TYPES_FAILURE';

// company status
export const GET_COMPANY_STATUS_REQUEST = 'GET_COMPANY_STATUS_REQUEST';
export const GET_COMPANY_STATUS_SUCCESS = 'GET_COMPANY_STATUS_SUCCESS';
export const GET_COMPANY_STATUS_FAILURE = 'GET_COMPANY_STATUS_FAILURE';

// update company
export const UPDATE_COMPANY_PROFILE_REQUEST = 'UPDATE_COMPANY_PROFILE_REQUEST';
export const UPDATE_COMPANY_PROFILE_SUCCESS = 'UPDATE_COMPANY_PROFILE_SUCCESS';
export const UPDATE_COMPANY_PROFILE_FAILURE = 'UPDATE_COMPANY_PROFILE_FAILURE';

// update company info
export const UPDATE_COMPANY_INFO_REQUEST = 'UPDATE_COMPANY_INFO_REQUEST';
export const UPDATE_COMPANY_INFO_SUCCESS = 'UPDATE_COMPANY_INFO_SUCCESS';
export const UPDATE_COMPANY_INFO_FAILURE = 'UPDATE_COMPANY_INFO_FAILURE';

// FOLLOW A COMPANY
export const FOLLOW_COMPANY_REQUEST = 'FOLLOW_COMPANY_REQUEST';
export const FOLLOW_COMPANY_SUCCESS = 'FOLLOW_COMPANY_SUCCESS';
export const FOLLOW_COMPANY_FAILURE = 'FOLLOW_COMPANY_FAILURE';

// UNFOLLOW A COMPANY
export const UNFOLLOW_COMPANY_REQUEST = 'UNFOLLOW_COMPANY_REQUEST';
export const UNFOLLOW_COMPANY_SUCCESS = 'UNFOLLOW_COMPANY_SUCCESS';
export const UNFOLLOW_COMPANY_FAILURE = 'UNFOLLOW_COMPANY_FAILURE';

// delete company info
export const DELETE_COMPANY_INFO_REQUEST = 'DELETE_COMPANY_INFO_REQUEST';
export const DELETE_COMPANY_INFO_SUCCESS = 'DELETE_COMPANY_INFO_SUCCESS';
export const DELETE_COMPANY_INFO_FAILURE = 'DELETE_COMPANY_INFO_FAILURE';

// delete comapny request
export const DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAILURE = 'DELETE_COMPANY_FAILURE';

// get admin of company
export const GET_COMPANY_ADMINS_REQUEST = 'GET_COMPANY_ADMINS_REQUEST';
export const GET_COMPANY_ADMINS_SUCCESS = 'GET_COMPANY_ADMINS_SUCCESS';
export const GET_COMPANY_ADMINS_FAILURE = 'GET_COMPANY_ADMINS_FAILURE';


// add company admin
export const ADD_COMPANY_ADMIN_REQUEST = 'ADD_COMPANY_ADMIN_REQUEST';
export const ADD_COMPANY_ADMIN_SUCCESS = 'ADD_COMPANY_ADMIN_SUCCESS';
export const ADD_COMPANY_ADMIN_FAILURE = 'ADD_COMPANY_ADMIN_FAILURE';

// delete admin
export const DELETE_COMPANY_ADMIN_REQUEST = 'DELETE_COMPANY_ADMIN_REQUEST';
export const DELETE_COMPANY_ADMIN_SUCCESS = 'DELETE_COMPANY_ADMIN_SUCCESS';
export const DELETE_COMPANY_ADMIN_FAILURE = 'DELETE_COMPANY_ADMIN_FAILURE';

// update super user
export const UPDATE_COMPANY_SUPER_USER_REQUEST = 'UPDATE_COMPANY_SUPER_USER_REQUEST';
export const UPDATE_COMPANY_SUPER_USER_SUCCESS = 'UPDATE_COMPANY_SUPER_USER_SUCCESS';
export const UPDATE_COMPANY_SUPER_USER_FAILURE = 'UPDATE_COMPANY_SUPER_USER_FAILURE';

// reset company admin
export const RESET_COMPANY_ADMINS = 'RESET_COMPANY_ADMINS';

//get company Activity
export const GET_COMPANY_ACTIVITY_REQUEST = 'GET_COMPANY_ACTIVITY_REQUEST';
export const GET_COMPANY_ACTIVITY_SUCCESS = 'GET_COMPANY_ACTIVITY_SUCCESS';
export const GET_COMPANY_ACTIVITY_FAILURE = 'GET_COMPANY_ACTIVITY_FAILURE';
export const RESET_COMPANY_PROFILE = 'RESET_COMPANY_PROFILE';
//reset company Activity
export const RESET_COMPANY_ACTIVITY = 'RESET_COMPANY_ACTIVITY';

//get photos for company
export const GET_COMPANY_PHOTO_REQUEST = 'GET_COMPANY_PHOTO_REQUEST';
export const GET_COMPANY_PHOTO_SUCCESS = 'GET_COMPANY_PHOTO_SUCCESS';
export const GET_COMPANY_PHOTO_FAILURE = 'GET_COMPANY_PHOTO_FAILURE';
// reset company photos
export const RESET_COMPANY_PHOTOS = 'RESET_COMPANY_PHOTOS';
// setting photo data
export const SET_COMPANY_PHOTOS_DATA = 'SET_COMPANY_PHOTOS_DATA';

// GET USERS WITHOUT ADMINS
export const GET_USERS_WITHOUT_ADMIN_REQUEST = 'GET_USERS_WITHOUT_ADMIN_REQUEST';
export const GET_USERS_WITHOUT_ADMIN_SUCCESS = 'GET_USERS_WITHOUT_ADMIN_SUCCESS';
export const GET_USERS_WITHOUT_ADMIN_FAILURE = 'GET_USERS_WITHOUT_ADMIN_FAILURE';

// company all constants
export const GET_ALL_COMPANIES_REQUEST = 'GET_ALL_COMPANIES_REQUEST';
export const GET_ALL_COMPANIES_SUCCESS = 'GET_ALL_COMPANIES_SUCCESS';
export const GET_ALL_COMPANIES_FAILURE = 'GET_ALL_COMPANIES_FAILURE';
export const RESET_ALL_COMPANIES = 'RESET_ALL_COMPANIES';
import React, { useState } from 'react';
import LoginViaPhone1 from './LoginViaPhone1';
import LoginViaPhone2 from './LoginViaPhone2';
const LoginViaPhone = (props) => {
  const [stepNo, setStepNo] = useState(1);
  const [phone, setPhoneLogin] = useState('');
  const [countryCode, setCountryCodeLogin] = useState('+91');

  return (
    <React.Fragment>
      {stepNo === 1 ? (
        <LoginViaPhone1
          setStepNo={setStepNo}
          setPhoneLogin={setPhoneLogin}
          setCountryCodeLogin={setCountryCodeLogin}
          {...props}
        />
      ) : (
        <LoginViaPhone2 setStepNo={setStepNo} phone={phone} countryCode={countryCode} {...props} />
      )}
    </React.Fragment>
  );
};

export default LoginViaPhone;

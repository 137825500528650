import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import get from "lodash/get";
import ReactTooltip from "react-tooltip";
// import Cookies from 'universal-cookie';
import { saveDonationModalData, toggleProfileMenuModal } from "../actions/common/modals";
import Avatar from "./user/Avatar";
import { companiesLink, miscLinks, profileLinks } from "../utils/internalLinks";
import { ProfileUrls } from "../utils/links";
import CloseIcon from "../assets/images/common/modals/close.svg";
import PassbookIcon from "../assets/images/profile/view/passbook.svg";
import PaidProgramIcon from "../assets/images/profile/view/paidprogram.svg";
import ChevronRight from "../assets/images/business/chevron-right.svg";
import { logoutUserLocally, userLogout } from "../actions/login/logout";
import { decodeString } from "../utils/helper";
import UserName from "./profile/UserName";
import { showProfilePassbookModal } from "../actions/common/modals";
import { genericErrorText } from "../utils/commonVariables";
import DonationModal from "./modals/collaborations/DonationModal";
import { setShowDonationModal, setHideDonationModal } from "../actions/common/modals";
import { prefrenceData } from "../utils/tastingHelper";
import CommonPortal from "./modals/common/CommonPortal";

class ProfilePopUp extends Component {
  constructor(props) {
    super(props);
    // binding function to toggle modal
    this.toggleModal = this.toggleModal.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleOpenPassbook = this.handleOpenPassbook.bind(this);
    this.handleOpenPaidTasterProgram =
      this.handleOpenPaidTasterProgram.bind(this);
    // this.handleDonation= this.handleDonation.bind(this);  
    this.tooltipRef = React.createRef();
    ReactTooltip.hide(this.tooltipRef);
  }

  toggleModal(type) {
    const { toggleProfileMenuModal } = this.props;
    toggleProfileMenuModal(type);
  }

  handleLogout() {
    const { logoutUserLocally, history, token } = this.props;
    // const cookies = new Cookies();
    // cookies.remove('passwordVerified');

    userLogout(token)
      .then((res) => {
        if (!res) {
          alert(genericErrorText.general);
        } else {
          logoutUserLocally();
          history.replace(miscLinks.home);
        }
      })
      .catch((error) => {
        alert(genericErrorText.general);
        return false;
      });
  }

  handleOpenPassbook(earning) {
    const { history } = this.props;
    if (earning > 0) {
      history.push(profileLinks.myPassbook);
    } else {
      ReactTooltip.show(this.tooltipRef);
    }
  }

  handleOpenPaidTasterProgram() {
    const { history } = this.props;
    history.push(profileLinks.paidTasterProgram);
  }

  render() {
    const {
      user: {
        user: { profile, companies },
      },
      isMobile,
      handleDonationModal
    } = this.props;
    const isPaidTester = get(profile, "is_paid_taster", 0);
    const earning = get(profile, "payment.earning", 0);
    const formattedEarning = decodeString(
      get(profile, "payment.formatted_earning", "")
    );

    if (!profile)
      return (
        <div className="profile-menu-popup">
          <div className="profile-menu-popup__list__container profile-menu-popup__list__container--secondary">
            <ul className="profile-menu-popup__list">
              <li
                className="profile-menu-popup__list__item"
                onClick={this.handleLogout}
              >
                <button className="profile-menu-popup__list__title">
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      );
    return (
      <>
        <div className="profile-menu-popup">
          <div>
            <Link
              to={ProfileUrls.userProfileLink(profile.handle, profile.id)}
              className="profile-menu-popup__head"
            >
              <div className="profile-menu-popup__head__img-container">
                <Avatar
                  className="profile-menu-popup__head__img"
                  src={
                    profile.image_meta
                      ? JSON.parse(profile.image_meta).original_photo
                      : ""
                  }
                  alt="user"
                  profile={profile}
                />
              </div>
              <div className="profile-menu-popup__head__details">
                <p className="profile-menu-popup__head__name">
                  <UserName
                    isVerified={!!profile.verified}
                    addClassToText={true}
                    name={profile.name}
                  />
                </p>
                <p className="profile-menu-popup__head__handle">
                  @{profile.handle}
                </p>
              </div>
            </Link>
            {isMobile && (
              <button className="icon-btn profile-menu-popup__close">
                <img src={CloseIcon} alt="close profile popup" />
              </button>
            )}
          </div>
          <div className="profile-menu-popup__list__container">
            <ul className="profile-menu-popup__list profile-menu-popup__list--fixed">
              {companies &&
                !!companies.length &&
                companies.map((company, companyIndex) => {
                  if (!company) return null;
                  const companyName = decodeString(company.name);
                  return (
                    <li key={`companies-${companyIndex}-key`}>
                      <Link
                        className="profile-menu-popup__list__item"
                        to={companiesLink.view(company.id)}
                      >
                        <Avatar
                          alt={`${companyName} profile link`}
                          src={company.logo}
                          className="profile-menu-popup__list__img"
                          isCompany={true}
                        />
                        <p className="profile-menu-popup__list__title">
                          <UserName
                            isVerified={!!company.verified}
                            addClassToText={true}
                            name={companyName}
                          />
                        </p>
                      </Link>
                    </li>
                  );
                })}
            </ul>
            <div
              className="profile-menu-popup__list__item"
              onClick={() => this.toggleModal("company")}
            >
              <span className="profile-menu-popup__list__title profile-menu-popup__list__title--primary">
                +Create a company page
              </span>
            </div>
          </div>

          <div className="profile-menu-popup__list__container profile-menu-popup__list__container--secondary">
            <ul className="profile-menu-popup__list">
              {!isPaidTester && (
                <li className="profile-menu-popup__paidprogram">
                  <div
                    className="profile-menu-popup__paidprogram__header cursor-pointer"
                    onClick={this.handleOpenPaidTasterProgram}
                  >
                    <img
                      className="profile-menu-popup__paidprogram__header__icon"
                      src={PaidProgramIcon}
                      alt="PaidProgramIcon"
                    />
                    <span className="profile-menu-popup__paidprogram__header__text">
                      Paid Taster Program
                    </span>
                    <img
                      className="profile-menu-popup__paidprogram__header__chevron"
                      src={ChevronRight}
                      alt="chevron right"
                    />
                  </div>
                </li>
              )}

              <li className="profile-menu-popup__mypassbook">
                <div className="profile-menu-popup__mypassbook__header">
                  <img
                    className="profile-menu-popup__mypassbook__header__icon"
                    src={PassbookIcon}
                    alt="passbook"
                  />
                  <span className="profile-menu-popup__mypassbook__header__textmy">
                    My{" "}
                  </span>
                  <span className="profile-menu-popup__mypassbook__header__textpassbook">
                    Passbook
                  </span>
                </div>
              </li>

              <li className="profile-menu-popup__passbook">
                <React.Fragment>
                  <div className="profile-menu-popup__passbook__earningdiv">
                    <div
                      className="profile-menu-popup__passbook__earning cursor-pointer"
                      onClick={() => this.handleOpenPassbook(earning)}
                      ref={(ref) => (this.tooltipRef = ref)}
                      {...(earning > 0
                        ? {}
                        : { "data-tip": "No transaction history available" })}
                    >
                      <span className="profile-menu-popup__passbook__earning__text">
                        You&#39;ve earned
                      </span>
                      <span className="profile-menu-popup__passbook__earning__amount">
                        {formattedEarning}
                      </span>
                      <img
                        className="profile-menu-popup__passbook__earning__arrow"
                        src={ChevronRight}
                        alt="chevron right"
                      />
                    </div>

                    {!earning > 0 && (
                      <ReactTooltip
                        clickable
                        event="click"
                        place="bottom"
                        arrowColor="transparent"
                      />
                    )}
                  </div>
                </React.Fragment>
              </li>

              {!!isPaidTester && <li className="profile-menu-popup__passbook profile-menu-popup__reward_preference">
                <React.Fragment>
                  <div className="profile-menu-popup__passbook__earningdiv">
                    <div
                      className="profile-menu-popup__reward_preference-div cursor-pointer"
                      onClick={() => handleDonationModal("profile")}
                    >
                      <span className="profile-menu-popup__passbook__earning__text">
                        Reward Preference
                      </span>
                      <div className="profile-menu-popup__reward_preference-flex">
                        {profile.is_donation !== null && <p
                          style={{ backgroundColor: !!profile.is_donation ? "#F47816" : "#4990E2" }}
                          className="profile-menu-popup__reward_preference-text-circle"></p>}
                        {profile.is_donation !== null && <p className="profile-menu-popup__reward_preference-text"
                        >
                          {!!profile.is_donation ? <p className="profile-menu-popup__reward_preference-text-donate">Donate</p> : <p>Self</p>}
                        </p>}
                        <img
                          className="profile-menu-popup__passbook__earning__arrow"
                          src={ChevronRight}
                          alt="chevron right"
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              </li>}
            </ul>
          </div>

          <div className="profile-menu-popup__list__container profile-menu-popup__list__container--secondary">
            <ul className="profile-menu-popup__list">
              <Link
                to={profileLinks.myCollaborations}
                className="profile-menu-popup__list__title"
              >
                <li className="profile-menu-popup__list__item">
                  My Collaborations
                </li>
              </Link>
              <Link
                to={profileLinks.myTastings}
                className="profile-menu-popup__list__title"
              >
                <li className="profile-menu-popup__list__item">
                  My Tastings
                </li>
              </Link>
              <Link
                to={profileLinks.mySurvey}
                className="profile-menu-popup__list__title"
              >
                <li className="profile-menu-popup__list__item">My Surveys</li>
              </Link>
              <Link
                to={profileLinks.myQuiz}
                className="profile-menu-popup__list__title"
              >
                <li className="profile-menu-popup__list__item">My Quizzes</li>
              </Link>
              <Link
                to={profileLinks.myInvitation}
                className="profile-menu-popup__list__title"
              >
                <li className="profile-menu-popup__list__item">My Invitations</li>
              </Link>
            </ul>
          </div>
          <div className="profile-menu-popup__list__container profile-menu-popup__list__container--secondary">
            <ul className="profile-menu-popup__list">
              <button
                onClick={() => this.toggleModal("invite")}
                className="profile-menu-popup__list__title"
              >
                <li className="profile-menu-popup__list__item">
                  + Invite Friends
                </li>
              </button>

              <Link
                className="profile-menu-popup__list__title"
                to={miscLinks.settings}
              >
                <li className="profile-menu-popup__list__item">Settings</li>
              </Link>
              {profile.addPassword ? (
                <li
                  className="profile-menu-popup__list__item"
                  onClick={() => this.toggleModal("create_password")}
                >
                  <button className="profile-menu-popup__list__title">
                    Create Password
                  </button>
                </li>
              ) : (
                <li
                  className="profile-menu-popup__list__item"
                  onClick={() => this.toggleModal("change_password")}
                >
                  <button className="profile-menu-popup__list__title">
                    Change Password
                  </button>
                </li>
              )}

              <a
                className="profile-menu-popup__list__title"
                href="mailto:support@tagtaste.com"
              >
                <li className="profile-menu-popup__list__item"> Contact Us</li>
              </a>

              <li
                className="profile-menu-popup__list__item"
                onClick={this.handleLogout}
              >
                <button className="profile-menu-popup__list__title">
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    token: state.login.token,
    showDonationModal: state.modalReducer.collaboration.showDonationModal,
  };
}

const mapDispatchToProps = {
  toggleProfileMenuModal,
  logoutUserLocally,
  showProfilePassbookModal,
  setShowDonationModal,
  setHideDonationModal,
  saveDonationModalData
};

ProfilePopUp.propTypes = {
  isMobile: PropTypes.bool,
  isLoggedOut: PropTypes.bool,
  user: PropTypes.object,
  history: PropTypes.object,
  toggleProfileMenuModal: PropTypes.func,
  logoutUserLocally: PropTypes.func,
  showProfilePassbookModal: PropTypes.func,
  handleDonationModal: PropTypes.func
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfilePopUp)
);

import {
    GET_PR_MANAGE_FILTERS_REQUEST,
    GET_PR_MANAGE_FILTERS_SUCCESS,
    GET_PR_MANAGE_FILTERS_FAILURE,
    RESET_PR_MANAGE_FILTERS,
    RESET_PR_MANAGE_SELECTED_FILTERS,
    UPDATE_PR_MANAGE_SELECTED_FILTERS,
    PR_MANAGE_REPORTS_INCLUDE_TASTER_ADD,
    PR_MANAGE_REPORTS_INCLUDE_TASTER_REMOVE,
    PR_MANAGE_REPORTS_EXCLUDE_TASTER_ADD,
    PR_MANAGE_REPORTS_EXCLUDE_TASTER_REMOVE
} from "../../../actions/collaborations/prManage/constants";

const INITIAL_STATE = {
    fetching: false,
    data: null,
    error: false,
    selectedFilters: {},
    exludeProfiles: [],
    includeProfiles: []
}

export default function filters(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_PR_MANAGE_FILTERS_REQUEST:
            return {
                ...state,
                data: [],
                error: false,
                errorMsg: "",
                fetching: true
            };
        case GET_PR_MANAGE_FILTERS_SUCCESS:
            return {
                ...state,
                fetching: false,
                data: action.payload
            }
        case GET_PR_MANAGE_FILTERS_FAILURE:
            return {
                ...state,
                ...(!action.error.__CANCEL__ && {
                    error: true,
                    errorMsg: action.error
                }),
                fetching: false
            }
        case UPDATE_PR_MANAGE_SELECTED_FILTERS:
            return {
                ...state,
                selectedFilters: action.payload
            }
        case RESET_PR_MANAGE_SELECTED_FILTERS:
            return {
                ...state,
                selectedFilters: INITIAL_STATE.selectedFilters
            }
        case PR_MANAGE_REPORTS_INCLUDE_TASTER_ADD:
            return {
                ...state,
                includeProfiles: [...state.includeProfiles, action.payload]
            }
        case PR_MANAGE_REPORTS_INCLUDE_TASTER_REMOVE:
            return {
                ...state,
                includeProfiles: state.includeProfiles.filter(_ => _.id !== action.payload.id)
            }
        case PR_MANAGE_REPORTS_EXCLUDE_TASTER_ADD:
            return {
                ...state,
                exludeProfiles: [...state.exludeProfiles, action.payload]
            }
        case PR_MANAGE_REPORTS_EXCLUDE_TASTER_REMOVE:
            return {
                ...state,
                exludeProfiles: state.exludeProfiles.filter(_ => _.id !== action.payload.id)
            }
        case RESET_PR_MANAGE_FILTERS:
            return INITIAL_STATE
        default:
            return state;
    }
}
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";

import { createAPoll } from "../../actions/common/createPoll";
import { updateAPoll, deleteOption } from "../../actions/common/updatePoll";
import CreatePollIcon from "../../assets/images/feed/create/poll.svg";
import UserSelect from "./UserSelect";
import CloseIcon from "../../assets/images/common/modals/close.svg";
import { hideEditAPostModal } from "../../actions/feed/common";
import { decodeString } from "../../utils/helper";
import TextPoll from "../polls/TextPoll";
import ImageOptionPoll from "../polls/ImageOptionPoll";
import ImageQuestionPoll from "../polls/ImageQuestionPoll";
import ImageContainerPoll from "../polls/ImageContainer";
import { pollTextLimits, pollTypes } from "../../utils/commonVariables";
import DiscardPostDraft from "../modals/common/DiscardPost";
import { polling } from "../../utils/internalLinks";
import { showToast } from "./utils";
import { Prompt } from "react-router-dom";

const typeOfPolls = {
  [pollTypes.imageQuestion]: ImageQuestionPoll,
  [pollTypes.imageOption]: ImageOptionPoll,
  [pollTypes.text]: TextPoll,
  image: ImageContainerPoll,
};

class CreatePoll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [
        { text: "", image: null },
        { text: "", image: null },
      ],
      title: "",
      optionsToRemove: [],
      isAnythingEmpty: true,
      hasEmptyOption: false,
      isUserCompany: false,
      isSubmitting: false,
      formIsHalfFilledOut: true,
      activeType: pollTypes.text,
      questionImage: null,
      questionVideo: null,
      handlePollCloseClick: null,
      showDiscardModal: false,
      preview: null,
      generatingPreview: false,
      isUpdateError: false,
      updateErrormessage: null,
      imagePoll: false,
      imagePollType: false,
      textPoll: false,
      ...(props.isEdit && props.editablePost
        ? {
          isEdit: true,
          title: decodeString(props.editablePost.polling.title),
          options: props.editablePost.polling.options.map((_) => {
            return {
              ..._,
              text: decodeString(_.text),
              ...(_.image_meta && {
                image: _.image_meta,
              }),
            };
          }),
          preview: props.editablePost.polling.preview || null,
          hasEmptyOption: false,
          isUserCompany: !!props.editablePost.company,
          selectedUser:
            props.editablePost.company || props.editablePost.profile,
          activeType: props.editablePost.polling.type || 0,
          ...(props.editablePost.polling.image_meta && {
            questionImage: props.editablePost.polling.image_meta,
          }),
          ...(props.editablePost.polling.videos_meta && {
            questionVideo: props.editablePost.polling.videos_meta,
          }),
        }
        : {
          selectedUser: props.user.user.profile,
          isUserCompany: false,
        }),
    };
    // handling input change for title
    this.handleInputChange = this.handleInputChange.bind(this);
    // handling question image
    this.handleQuestionMedia = this.handleQuestionMedia.bind(this);
    // handling input change for poll option
    this.handleOptionsChange = this.handleOptionsChange.bind(this);
    // binding function to add options
    this.addToOptions = this.addToOptions.bind(this);
    // binding function for removing poll option
    this.removeFromOptions = this.removeFromOptions.bind(this);
    // binding function to create a poll
    this.handleSubmit = this.handleSubmit.bind(this);
    // binding function to update a poll
    this.handleUpdate = this.handleUpdate.bind(this);
    // Checking if any empty state
    this.checkForEmpty = this.checkForEmpty.bind(this);
    // handling the current type
    this.handleTypeSelect = this.handleTypeSelect.bind(this);
    // function to handle user change
    this.handleUserSelectChange = this.handleUserSelectChange.bind(this);
    // function to handle close or swtch
    this.handleSwitchOrClose = this.handleSwitchOrClose.bind(this);
    this._resetPollSwitchData = this._resetPollSwitchData.bind(this);
    this.handlePreview = this.handlePreview.bind(this);
    this.handleGeneratingPreview = this.handleGeneratingPreview.bind(this);
    this.handleImagePoll = this.handleImagePoll.bind(this);
    this.handleImagePollType = this.handleImagePollType.bind(this);
  }

  handleInputChange(title) {
    this.setState(
      {
        title,
      },
      () => {
        this.checkForEmpty();
      }
    );
  }

  handleTypeSelect(activeType) {
    this.setState({ activeType });
  }

  handleQuestionMedia(questionMedia) {
    if (questionMedia == null) {
      return this.setState({ questionImage: null, questionVideo: null }, () => {
        this.checkForEmpty();
      });
    }
    if (questionMedia.uploadingImage) {
      this.setState({ questionImage: { uploading: true } }, () => {
        this.checkForEmpty();
      });
    } else if (questionMedia.uploadingVideo) {
      this.setState({ questionVideo: { uploading: true } }, () => {
        this.checkForEmpty();
      });
    } else if (questionMedia && questionMedia.original_photo) {
      this.setState({ questionImage: questionMedia, questionVideo: null }, () => {
        this.checkForEmpty();
      });
    } else {
      this.setState({ questionImage: null, questionVideo: [questionMedia] }, () => {
        this.checkForEmpty();
      });
    }
  }


  handleOptionsChange(newValueObj, indexToUpdate) {
    this.setState(
      {
        options: this.state.options.map((option, optionIndex) => {
          if (optionIndex !== indexToUpdate) {
            return option;
          }
          return {
            ...option,
            ...newValueObj,
          };
        }),
      },
      () => {
        this.checkForEmpty();
      }
    );
  }

  checkForEmpty() {
    let isAnythingEmpty = false;
    if (!this.state.title.trim()) {
      isAnythingEmpty = true;
    }
    if (
      this.state.activeType === pollTypes.imageQuestion &&
      ((!this.state.questionImage || this.state.questionImage.uploading) &&
        (!this.state.questionVideo || this.state.questionVideo.uploading))
    ) {
      isAnythingEmpty = true;
    }

    if (
      this.state.activeType === pollTypes.text ||
      this.state.activeType === pollTypes.imageQuestion
    ) {
      this.state.options.forEach((option) => {
        if (!option.text.trim()) {
          isAnythingEmpty = true;
        }
      });
    } else if (this.state.activeType === pollTypes.imageOption) {
      this.state.options.forEach((option) => {
        if (
          !option.image ||
          (option.image && option.image.uploading) ||
          !option.text.trim()
        ) {
          isAnythingEmpty = true;
        }
      });
    }
    this.setState({
      isAnythingEmpty,
    });
  }

  componentDidMount() {
    // Checking if editable post
    const { isEdit, editablePost } = this.props;
    // updating if editable post
    if (isEdit) {
      // Checking count if anyone has played or not
      let isDisabled = false;
      editablePost.polling.options.forEach((option) => {
        if (option.count > 0) {
          isDisabled = true;
        }
      });
      this.setState(
        {
          isDisabled,
        },
        () => {
          this.checkForEmpty();
        }
      );
      document.body.style.overflow = "hidden";
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }

  // function to add options object
  addToOptions() {
    if (this.state.options.length === 4) return;
    this.setState({
      options: [...this.state.options, { text: "", image: null }],
      isAnythingEmpty: true,
    });
    setTimeout(() => {
      const optionListClass =
        this.state.activeType === pollTypes.imageOption
          ? ".poll-create__image__option__container"
          : ".poll-create-container__options";
      const lastElementInput = `${optionListClass} > li:last-child input[type="text"]`;
      if (document.querySelector(lastElementInput)) {
        document.querySelector(lastElementInput).focus();
      }
    }, 0);
  }

  // function to remove options
  removeFromOptions(indexToRemove, isEdit) {
    if (this.state.options.length === 2) {
      return;
    }
    // Remove only if id
    if (
      isEdit &&
      this.state.options[indexToRemove] &&
      this.state.options[indexToRemove].id
    ) {
      this.setState({
        optionsToRemove: [
          ...this.state.optionsToRemove,
          ...this.state.options.filter((_, index) => index === indexToRemove),
        ],
      });
    }
    this.setState(
      {
        options: this.state.options.filter(
          (_, index) => index !== indexToRemove
        ),
      },
      () => {
        this.checkForEmpty();
      }
    );
  }

  handleImagePoll() {
    this.setState({ imagePoll: false });
  }

  handleUpdate() {
    const {
      isDisabled,
      optionsToRemove,
      activeType,
      questionImage,
      questionVideo,
      isSubmitting,
      preview,
    } = this.state;
    if (isSubmitting) return;
    const {
      token,
      updateAPoll,
      editablePost,
      hideEditAPostModal,
      deleteOption,
    } = this.props,
      data = {
        title: this.state.title.trim(),
        options: this.state.options.map((option) => {
          return {
            text: option.text.trim(),
            ...(option.id && {
              id: option.id,
            }),
            ...(activeType === pollTypes.imageOption && {
              image_meta: JSON.stringify(option.image),
            }),
          };
        }),
        ...(activeType === pollTypes.imageQuestion &&
          questionImage && {
          question_image: JSON.stringify(questionImage),
        }),
        ...(activeType === pollTypes.imageQuestion &&
          questionVideo && {
          videos_meta: questionVideo,
        }),
        ...(preview && { preview }),
        type: activeType,
        ...(activeType === pollTypes.imageQuestion &&
          questionImage && {
          image_meta: JSON.stringify(questionImage),
        }),
      },
      pollId = editablePost.polling.id;
    this.setState({
      isSubmitting: true,
      formIsHalfFilledOut: false,
    });
    // checking if have to remove options
    if (optionsToRemove.length) {
      let dataToSend = {
        optionId: optionsToRemove.map((option) => option.id),
      };
      deleteOption(pollId, dataToSend, token).then((val) => {
        if (Object.keys(val.data).length && !val.errors.length) {
          updateAPoll(pollId, data, token).then((val) => {
            this.setState({
              isSubmitting: false,
            });
            if (val) {
              hideEditAPostModal();
              showToast("pollUpdate");
            }
          });
        } else {
          // after removing poll options render all revomed optin again
          const updated = this.state.optionsToRemove.length
            ? this.state.optionsToRemove.splice(
              0,
              this.state.optionsToRemove.length
            )
            : null;
          const data = [...this.state.options, ...updated].sort((a, b) =>
            a.id > b.id ? 1 : -1
          );
          this.setState({
            options: data,
            isDisabled: true,
            isSubmitting: false,
            isUpdateError: true,
            updateErrormessage: val.errors,
          });
        }
      });
    } else {
      updateAPoll(pollId, data, token).then((val) => {
        this.setState({
          isSubmitting: false,
        });
        if (val) {
          hideEditAPostModal();
          showToast("pollUpdate");
        } else {
          this.setState({
            isDisabled: true,
          });
        }
      });
    }
  }

  handleSubmit() {
    const { isDisabled, isSubmitting, activeType, questionImage, questionVideo, preview } =
      this.state;
    if (isDisabled || isSubmitting) return;
    this.setState({
      isSubmitting: true,
    });
    const { token, createAPoll, user, handleClose } = this.props,
      data = {
        title: this.state.title.trim(),
        options: this.state.options.map((option) => option.text.trim()),
        type: activeType,
        ...(activeType === pollTypes.imageQuestion &&
          questionImage && {
          question_image: JSON.stringify(questionImage),
        }),
        ...(activeType === pollTypes.imageQuestion &&
          questionVideo && {
          videos_meta: questionVideo,
        }),
        ...(activeType === pollTypes.imageOption && {
          option_images: this.state.options.map((option) =>
            JSON.stringify(option.image)
          ),
        }),
        ...(preview && { preview }),
      },
      creatorProfile = {
        profile: user.user.profile,
      };
    if (this.state.isUserCompany) {
      creatorProfile["company"] = this.state.selectedUser;
      data["company_id"] = this.state.selectedUser.id;
    }
    createAPoll(data, token, creatorProfile).then((val) => {
      this.setState({
        isSubmitting: false,
      });
      if (val) {
        handleClose("done");
        showToast("poll", polling.view(get(val, "polling.id", "")));
      }
    });
  }

  // function to handle user select
  handleUserSelectChange(selectedUser = null, isUserCompany = false) {
    if (!selectedUser) return;
    this.setState({
      selectedUser,
      isUserCompany,
    });
  }

  // method to reset poll data
  _resetPollSwitchData() {
    this.setState({
      options: [
        { text: "", image: null },
        { text: "", image: null },
      ],
      title: "",
      isAnythingEmpty: true,
      hasEmptyOption: false,
      questionImage: null,
      questionVideo: null,
      preview: null,
      generatingPreview: false,
    });
  }

  // method to handle close or switching between poll types
  handleSwitchOrClose(callback) {
    let isAnythingFilled =
      !!this.state.title.length ||
      !!this.state.questionImage ||
      !!this.state.questionVideo ||
      this.state.options.filter((_) => _.image || _.text).length ||
      this.state.isEdit;
    if (isAnythingFilled) {
      // if anything is filled, show users discard modal
      this.setState({
        showDiscardModal: true,
        handlePollCloseClick: () => {
          this.setState({
            showDiscardModal: false,
          });
          callback();
        },
      });
    } else {
      callback();
    }
  }

  // method to set preview
  handlePreview(preview) {
    this.setState({
      preview,
    });
  }

  // setting generating preview
  handleGeneratingPreview(generatingPreview) {
    this.setState({
      generatingPreview,
    });
  }
  handleImagePollType() {
    this.setState({ imagePollType: true });
  }

  componentDidUpdate() {
    if (
      (this.state.title != "" && this.state.formIsHalfFilledOut) ||
      (this.state.title == "" && this.state.isEdit)
    ) {
      window.onbeforeunload = function (e) {
        return "";
      };
    } else {
      window.onbeforeunload = null;
    }
  }

  render() {
    const {
      isAnythingEmpty,
      isDisabled,
      isSubmitting,
      options,
      questionImage,
      questionVideo,
      generatingPreview,
      title,
      activeType,
      preview,
      isEdit,
      isUpdateError,
      updateErrormessage,
      optionsToRemove,
    } = this.state;
    const { user, token, hideArrow, editablePost } = this.props;
    const TypeOfPoll = typeOfPolls[activeType] ? typeOfPolls[activeType] : null;
    const isQuestionMediaUploading = (questionImage && questionImage.uploading || questionVideo && questionVideo.uploading) || false;
    return (
      <>
        <Prompt
          when={title != "" || (title == "" && isEdit)}
          message={"Changes that you made may not be saved"}
        />
        {isEdit && isUpdateError ? (
          <p className="post-bar__post__poll-helper">{updateErrormessage}</p>
        ) : null}
        <div
          className={`post-bar__post ${!isEdit ? "post-bar__post--active" : ""
            }`}
        >
          {!isEdit && !hideArrow && (
            <div className="post-bar__post__arrow post-bar__post__arrow--poll"></div>
          )}
          <div
            className={
              this.state.imagePoll === true
                ? "multiple-image-poll-create-container"
                : `poll-create-container`
            }
          >
            {!isEdit && (
              <button
                className="post-bar__post__cancel"
                type="button"
                onClick={() => {
                  this.handleSwitchOrClose(this.props.handleClose);
                  this.setState({ imagePoll: false, imagePollType: false });
                }}
              >
                <img
                  className="post-bar__post__cancel__icon"
                  src={CloseIcon}
                  alt="close post update"
                />
              </button>
            )}
            <div style={{ position: "relative" }}>
              {this.state.selectedUser && (
                <UserSelect
                  isEdit={isEdit}
                  isUserCompany={this.state.isUserCompany}
                  selectedUser={this.state.selectedUser}
                  handleUserSelectChange={this.handleUserSelectChange}
                  companies={user.user.companies}
                  profile={user.user.profile}
                />
              )}
              {!isEdit && (
                <div
                  className={
                    this.state.imagePoll == true
                      ? "image-poll-create-container__type__container"
                      : "poll-create-container__type__container"
                  }
                >
                  <button
                    className={`poll-create-container__type
                                ${activeType === pollTypes.text
                        ? "poll-create-container__type--active"
                        : ""
                      }`}
                    onClick={() =>
                      this.handleSwitchOrClose(() => {
                        this._resetPollSwitchData();
                        this.handleTypeSelect(pollTypes.text);
                        this.setState({
                          imagePoll: false,
                          imagePollType: false,
                          textPoll: true,
                        });
                      })
                    }
                  >
                    Text Poll
                  </button>
                  <button
                    className={`poll-create-container__type
                                ${activeType !== pollTypes.text
                        ? "poll-create-container__type--active"
                        : ""
                      }`}
                    onClick={() =>
                      this.handleSwitchOrClose(() => {
                        this._resetPollSwitchData();
                        this.handleTypeSelect("image");
                        this.setState({
                          imagePoll: true,
                          imagePollType: false,
                          textPoll: false,
                        });
                      })
                    }
                  >
                    Media Poll
                  </button>
                </div>
              )}
            </div>
            <div
              className={
                (this.state.imagePollType == true || (editablePost && editablePost.polling.type == !pollTypes.text)) ? "poll-type-scroll" : ""
              }
            >
              <div
                className={(this.state.textPoll == true || (editablePost && editablePost.polling.type == pollTypes.text)) ? "text-poll-type" : ""}
              >
                <TypeOfPoll
                  editablePost={isEdit ? editablePost.polling.options : ""}
                  options={options}
                  isEdit={isEdit}
                  title={title}
                  questionImage={questionImage}
                  questionVideo={questionVideo}
                  token={token}
                  preview={preview}
                  disabled={isEdit && (isDisabled || generatingPreview)}
                  handleTitleChange={this.handleInputChange}
                  handleQuestionMedia={this.handleQuestionMedia}
                  handleOptionsChange={this.handleOptionsChange}
                  handleRemoveOptions={this.removeFromOptions}
                  handlePreview={this.handlePreview}
                  handleGeneratingPreview={this.handleGeneratingPreview}
                  optionsToRemove={optionsToRemove}
                  handleSelect={this.handleTypeSelect}
                  handleImagePoll={this.handleImagePoll}
                  handleImagePollType={this.handleImagePollType}
                />
                {this.state.options.length < 4 && activeType !== "image" && (
                  <button
                    className="poll-create-container__add-btn"
                    onClick={this.addToOptions}
                  >
                    <span className="poll-create-container__add-btn__text">
                      + Add a choice
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
          {activeType !== "image" && (
            <div className="post-bar__post__action-container">
              <div className="post-bar__post__action-container__actions">
                <p className="post-bar__post__action-container__helper">
                  Expires in 7 days
                </p>
              </div>
              <div className="post-bar__post__action-container__action">
                <p className="post-bar__post__action-container__helper">
                  {title.length}/{pollTextLimits.title}
                </p>
                <button
                  className={`post-bar__post__action-btn post-bar__post__action-btn--poll
                            ${(
                      !isUpdateError
                        ? isAnythingEmpty || isSubmitting || isQuestionMediaUploading
                        : ""
                    )
                      ? "post-bar__post__action-btn--disabled"
                      : ""
                    }`}
                  disabled={
                    !isUpdateError ? isAnythingEmpty || isSubmitting || isQuestionMediaUploading : ""
                  }
                  onClick={() => {
                    if (isEdit) {
                      this.handleUpdate();
                      return;
                    }
                    this.handleSubmit();
                    return;
                  }}
                >
                  <img
                    className="post-bar__post__action-btn__icon__poll"
                    src={CreatePollIcon}
                    alt="post btn"
                  />
                  <span className="post-bar__post__action-btn__text">
                    {isEdit ? "Update" : "Create Poll"}
                  </span>
                </button>
              </div>
            </div>
          )}
        </div>
        {this.state.showDiscardModal && (
          <DiscardPostDraft
            handleAction={this.state.handlePollCloseClick}
            handleClose={() => this.setState({ showDiscardModal: false })}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.login.token,
    user: state.user,
  };
}

const mapDispatchToProps = {
  createAPoll,
  updateAPoll,
  deleteOption,
  hideEditAPostModal,
};

CreatePoll.propTypes = {
  isEdit: PropTypes.bool,
  editablePost: PropTypes.object,
  handleClose: PropTypes.func,
  user: PropTypes.object,
  token: PropTypes.string,
  updateAPoll: PropTypes.func,
  hideEditAPostModal: PropTypes.func,
  deleteOption: PropTypes.func,
  createAPoll: PropTypes.func,
  hideArrow: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePoll);

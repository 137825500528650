import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ShareAPost from "../ShareAPost";
import LikeModal from "../LikeModal";
import EditAPostModal from "../EditAPostModal";
import SendMessage from "../../messages/SendMessage";
import PhotoModal from "../PhotoModal";

function PostCommonModals(props) {
  const [postModals, setPostModals] = useState(props.postModals),
    [preview, setPreview] = useState(props.preview),
    [photoModal, setPhotoModal] = useState(props.photoModal),
    [token, setToken] = useState(props.token);
  useEffect(() => {
    setPostModals(props.postModals);
  }, [props.postModals]);
  useEffect(() => {
    setPreview(props.preview);
  }, [props.preview]);
  useEffect(() => {
    setToken(props.token);
  }, [props.token]);
  useEffect(() => {
    setPhotoModal(props.photoModal);
  }, [props.photoModal]);
  return (
    <div>
      {postModals &&
        postModals.shareAPost &&
        postModals.shareAPost.isVisible &&
        postModals.shareAPost.post && <ShareAPost />}
      {postModals && postModals.likeModal && postModals.likeModal.isVisible && (
        <LikeModal />
      )}
      {postModals &&
        postModals.editAPost &&
        postModals.editAPost.isVisible &&
        postModals.editAPost.post && (
          <EditAPostModal
            postType={postModals.editAPost.postType}
            post={postModals.editAPost.post}
          />
        )}
      {preview &&
        preview["postPreview"] &&
        !preview["postPreview"].fetching &&
        preview["postPreview"].preview && (
          <SendMessage preview={preview["postPreview"].preview} token={token} />
        )}
      {photoModal && photoModal.isVisible && <PhotoModal />}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    token: state.login.token,
    postModals: state.modalReducer.postModals,
    preview: state.feedReducer.preview,
    photoModal: state.modalReducer.photoModal,
  };
}

export default withRouter(connect(mapStateToProps)(PostCommonModals));

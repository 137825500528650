import { CREATE_COMPANY_REQUEST,
    CREATE_COMPANY_SUCCESS,
    CREATE_COMPANY_FAILURE } from './constants';
import { companyUrls, getBaseUrl } from '../../utils/links';
import { onRequest,
    onSuccess,
    onError,
    getResponseData} from '../../utils/helper';
import Axios from 'axios';
const CancelToken = Axios.CancelToken;

// create company request
export let createCompanyCancelToken = undefined;
export const createCompany = (profileId,
    data,
    token) => {
    return dispatch => {
        if (createCompanyCancelToken) createCompanyCancelToken();
        dispatch(onRequest(CREATE_COMPANY_REQUEST));
        let url = `${getBaseUrl()}${companyUrls.create(profileId)}`,
            options = {
                url,
                data,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                method: 'POST',
                cancelToken: new CancelToken((c) => createCompanyCancelToken = c)
            };
        return Axios(options)
            .then(response => {
                let successData = getResponseData(response);
                if (successData) {
                    dispatch(onSuccess(CREATE_COMPANY_SUCCESS, successData));
                } else {
                    dispatch(onError(CREATE_COMPANY_FAILURE, response.data.errors));
                }
                return successData;
            })
            .catch(error => {
                dispatch(onError(CREATE_COMPANY_FAILURE, error));
                return false;
            })
    }
}
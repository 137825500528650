import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { miscLinks } from '../../../../utils/internalLinks';
import LeftArrow from '../../../../assets/images/login/left-arrow.svg';
import CloseIcon from '../../../../assets/images/login/close.svg';
import { countries, phoneMaxLength } from '../../../../utils/commonVariables';
import { postOTPSend, postOTPSendCancelToken } from '../../../../actions/profile/phone';

const errorTexts = {
  invalidPhone: 'Please enter a valid phone number',
};

const LoginViaPhone1 = (props) => {
  const [countryCode, setCountryCode] = useState('+91');
  const [phone, setPhone] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [inputClass, setInputClass] = useState(
    'loginphone__container loginphone__container__noerror',
  );
  const [submitDisable, setSubmitDisable] = useState(true);

  useEffect(() => {
    return () => {
      if (postOTPSendCancelToken) postOTPSendCancelToken();
    };
  }, []);

  const phoneLengthInValid = (phone) => {
    if (phone.length > 7 && phone.length <= phoneMaxLength) {
      setSubmitDisable(false);
      return false;
    } else {
      setSubmitDisable(true);
      return true;
    }
  };

  const handlePhoneChange = (e) => {
    const phone = e.target.value;
    let invalidPhone;

    setPhone(phone);
    if (isNaN(phone)) {
      invalidPhone = true;
    } else {
      invalidPhone = phoneLengthInValid(phone);
    }
    setInvalidPhone(invalidPhone);

    if (invalidPhone || otpError) {
      setInputClass('loginphone__container loginphone__container__error');
    } else {
      setInputClass('loginphone__container loginphone__container__noerror');
    }

    if (otpError) setOtpError('');
  };

  // adding + to value of the country code
  const handleCallingCodeChange = (e) => {
    let value = e.target.value;
    setCountryCode(`+${value}`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const { setStepNo, setPhoneLogin, setCountryCodeLogin } = props;

    const dataToSend = {
      profile: {
        country_code: countryCode,
        mobile: phone,
        platform: 'web',
      },
    };

    postOTPSend(dataToSend).then((response) => {
      if (response.status) {
        if (get(response, 'data.data')) {
          setStepNo(2);
          setPhoneLogin(phone);
          setCountryCodeLogin(countryCode);
        } else {
          const error = get(response, 'data.errors');
          setOtpError(error);
        }
        setSubmitting(false);
      } else {
        alert('Some error occured');
        setSubmitting(false);
      }
    });
  };

  return (
    <div className="login-form">
      <div className="login-form__backdrop">
        {props.shouldOnlyToggle ? (
          <button type="button" className="login-form__cancel" onClick={props.toggleLoginForm}>
            <img src={CloseIcon} alt="go to homepage" />
          </button>
        ) : (
          <Link to={miscLinks.home} className="login-form__cancel">
            <img src={CloseIcon} alt="go to homepage" />
          </Link>
        )}
        <div className="login-form__container">
          <div className="login-form__header">
            <img
              onClick={
                props.shouldOnlyToggle && props.openSocialLogin
                  ? props.openSocialLogin
                  : props.toggleLoginForm
              }
              className="cursor-pointer login-form__header__icon"
              src={LeftArrow}
              alt="other login channels"
            />
            <p className="login-form__title">Log in via Phone</p>
          </div>
          <form
            className="login-form__form"
            onSubmit={handleSubmit}
            disabled={submitting || submitDisable}
          >
            <div className={inputClass}>
              <select
                className="loginphone__countrycode"
                onChange={handleCallingCodeChange}
                value={countryCode}
              >
                <option value={countryCode}>{countryCode}</option>
                {countries.map((country, index) => {
                  return (
                    <option key={`calling_code-${index}`} value={country.calling_code}>
                      {country.country} {country.emoji} (+{country.calling_code})
                    </option>
                  );
                })}
              </select>
              <div className="loginphone__partition"></div>
              <input
                type="text"
                value={phone}
                name="phone"
                autoFocus
                maxLength={phoneMaxLength}
                className="loginphone__phone"
                onChange={handlePhoneChange}
              />
            </div>
            <div className="loginphone__errors">
              {invalidPhone && <p className="error-text">{errorTexts.invalidPhone}</p>}
              {otpError && <p className="error-text">{otpError}</p>}
            </div>

            <button
              type="submit"
              disabled={submitting || submitDisable}
              className={`login-form__action__btn login-form__action__btn--default`}
            >
              Send OTP
            </button>
          </form>
          <div>
            <p className="login-form__link">
              Don&apos;t have an account?
              {props.shouldOnlyToggle && props.openSignUpForm ? (
                <button
                  onClick={props.openSignUpForm}
                  className="login-form__link login-form__link--btn login-form__link--blue"
                >
                  Sign up
                </button>
              ) : (
                <Link className="login-form__link login-form__link--blue" to={miscLinks.register}>
                  Sign up
                </Link>
              )}
            </p>
            <p className="login-form__helper-link">
              By signing up on TagTaste, you are agreeing to our &nbsp;
              <Link
                className="login-form__helper-link login-form__helper-link--blue"
                to={miscLinks.terms}
              >
                Terms &amp; Conditions
              </Link>
              &nbsp;and&nbsp;
              <Link
                className="login-form__helper-link login-form__helper-link--blue"
                to={miscLinks.privacy}
              >
                Privacy Policy
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

LoginViaPhone1.propTypes = {
  toggleLoginForm: PropTypes.func,
  shouldOnlyToggle: PropTypes.bool,
  publicPageLink: PropTypes.string,
  openSignUpForm: PropTypes.func,
  openSocialLogin: PropTypes.func,
  history: PropTypes.object,
  login: PropTypes.func,
  setStepNo: PropTypes.func,
  setPhoneLogin: PropTypes.func,
  setCountryCodeLogin: PropTypes.func,
};

export default LoginViaPhone1;

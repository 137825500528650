import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  // Link, NavLink,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import { getPremiumCompanies } from "../../actions/premium";
import PostMobileIcon from "../../assets/images/feed/create/post-mobile.svg";
import CreateBarDesktop from "./CreateBarDesktop";
import CreateBarMobile from "./CreateBarMobile";
import {
  surveyLinks,
  collaborationInternalLinks,
  quizLinks,
} from "../../utils/internalLinks";
import { typesOfCollaborations } from "../../utils/collaborationHelper";
import CreatePost from "./CreatePost";
import CreatePoll from "./CreatePoll";
import "react-toastify/dist/ReactToastify.css";
import { gaEvents } from "../../utils/events";
import { QUIZ_GA_EVENTS_TITLE } from "../../utils/quizHelper";

const CreateBar = (props) => {
  const { isMobile, token, handleOpen, user, history } = props;
  const [showMobileCreateMenu, setShowMobileCreateMenu] = useState(false);

  const toggleCreateModalMobile = () => {
    if (showMobileCreateMenu) {
      document.querySelector("body").classList.remove("overflow-hidden");
      document.getElementsByClassName("top-bar")[0].style.display = "block";
    } else {
      document.querySelector("body").classList.add("overflow-hidden");
      document.getElementsByClassName("top-bar")[0].style.display = "none";
    }
    setShowMobileCreateMenu(!showMobileCreateMenu);
  };

  // mobileCreate
  const [type, setType] = useState(null);

  const handleUpdate = (type = null) => {
    if (type) {
      setType(type);
      handleOpen(true);
      return;
    }
    setType(null);
    handleOpen(false);
  };

  useEffect(() => {
    if (type === "poll") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [type]);
  const handleSurveyOrPollClose = () => {
    handleUpdate();
  };

  const handleLinkButtonClick = (type) => {
    switch (type) {
      case "post":
        handleUpdate("post");
        break;
      case "collaboration":
        history.push(collaborationInternalLinks.create());
        break;
      case "poll":
        handleUpdate("poll");
        break;
      case "survey":
        history.push(surveyLinks.create);
        break;
      case "quiz":
        gaEvents.quiz.createBar(QUIZ_GA_EVENTS_TITLE);
        history.push(quizLinks.create);
        break;
      case "review":
        history.push(
          collaborationInternalLinks.create(typesOfCollaborations.productReview)
        );
        break;
      case "consistency":
        history.push(
          collaborationInternalLinks.create(
            `${typesOfCollaborations.productReview}?track_consistency=true`
          )
        );
        break;
      default:
        break;
    }
    document.querySelector("body").classList.remove("overflow-hidden");
    document.getElementsByClassName("top-bar")[0].style.display = "block";
    setShowMobileCreateMenu(false);
  };

  return (
    <>
      {isMobile ? (
        <div className="createbar__mobile" onClick={toggleCreateModalMobile}>
          <img alt="post" src={PostMobileIcon} />
          <span className="createbar__mobile__text">Post Something</span>
        </div>
      ) : (
        <CreateBarDesktop token={token} handleOpen={handleOpen} user={user} />
      )}

      {showMobileCreateMenu && (
        <CreateBarMobile
          toggleCreateModalMobile={toggleCreateModalMobile}
          handleOpen={handleOpen}
          handleLinkButtonClick={handleLinkButtonClick}
        />
      )}

      {type && (
        <div className="post-bar__create-modal">
          {type === "post" && (
            <CreatePost handleClose={() => handleUpdate()} hideArrow />
          )}
          {type === "poll" && (
            <CreatePoll handleClose={handleSurveyOrPollClose} hideArrow />
          )}
        </div>
      )}
      {type && <div className="create-bg"></div>}
      <ToastContainer className="createbar__toast" />
    </>
  );
};

CreateBar.propTypes = {
  isMobile: PropTypes.bool,
  token: PropTypes.string,
  user: PropTypes.object,
  handleOpen: PropTypes.func,
  history: PropTypes.object,
};

export default withRouter(connect(null, { getPremiumCompanies })(CreateBar));

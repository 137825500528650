import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { emailVerify } from '../../../actions/profile/view';
import Check from '../../../assets/images/profile/edit/check.svg';
import { getCurrentUser } from '../../../actions/user';
import { emailVerification, emailOtpVerification } from '../../../actions/login/register';
import VerifiedGreen from "../../../assets/images/profile/edit/verfied-green.svg";
import ErrorRed from "../../../assets/images/profile/edit/error-red.svg";
import get from "lodash/get";
import {
  getTwoDigits,
} from "../../../utils/helper";
const texts = {
  title: "Personal",
  otp: "OTP",
},
  errors = {
    invalidPhone: "Please enter valid phone number",
    invalidDate: "Please enter valid date",
    otpIncorrect: "Incorrect OTP",
  };
function Email(props) {
  const [hasSendVerifiedEmail, setHasSendVerifiedEmail] = useState(false),
    [processing, setProcessing] = useState(false),
    [isVerifyingMail, setIsVerifyIngMail] = useState(false),
    [verifiedEmail, setVerifiedEmail] = useState(0),
    [otpMail, setOtpMail] = useState(""),
    [otpSecondsMail, setOtpSecondsMail] = useState(59),
    [otpMinMail, setOtpMinMail] = useState(0),
    [canResendOtpMail, setCanResendOtpMail] = useState(false),
    [otpInvalidMail, setOtpInvalidMail] = useState(false),
    [apiErrorTextMail, setApiErrorTextMail] = useState(false),
    { addToFilledItems } = props;
  let timer = undefined
  let timerRef = useRef(null);
  const _verifyOtpMail = (e) => {
    if (!otpMail) return;
    const { token, otpVerify, user, emailOtpVerification } = props;
    const data = {
      email: user.user.email,
      otp: otpMail,
    };
    emailOtpVerification(data, token, "personal").then((val) => {
      if (!!val.data) {
        setVerifiedEmail(1)
        setOtpMail("")
        setIsVerifyIngMail(false)
        setOtpInvalidMail(false)
        setApiErrorTextMail("")
        getUser()
      } else {
        setOtpInvalidMail(true)
        const errorMsg = get(val, "errors");
        if (errorMsg) {
          setApiErrorTextMail(errorMsg)
        } else {
          setApiErrorTextMail("")
        }
      }
    });
  }
  const _getotpMail = (e) => {
    const { user, token } = props;
    let dataToSend = {
      email: user.user.email,
      platform: "web"
    };
    setApiErrorTextMail("")
    setOtpMail("")
    setOtpInvalidMail("")
    emailVerification(dataToSend, token).then((res) => {
      if (res && !get(res, "errors", []).length) {
        setIsVerifyIngMail(true)
        setCanResendOtpMail(false)
        setOtpMinMail(0)
        setOtpSecondsMail(59)
      } else {
        const errorMsg = get(res, "errors");
        if (errorMsg) {
          setApiErrorTextMail(errorMsg)
        } else {
          setApiErrorTextMail("")
        }
      }
    });
  }
  const { user: {
    user: {
      email,
      verified_at,
      profile
    } },
    token,
    shouldCheckForNewProfile,
    getCurrentUser } = props;
  // sending email
  const sendVerificationEmail = () => {
    if (processing) return;
    setProcessing(true);
    emailVerify(token)
      .then(_ => {
        if (_) {
          setHasSendVerifiedEmail(true);
          setProcessing(false);
        }
      })
  }

  // updating profile object
  const getUser = () => {
    if (processing) return;
    setProcessing(true);
    getCurrentUser(token)
      .then(_ => {
        setProcessing(false);
        if (_.verified_at) {
          addToFilledItems('verified_email', true);
        }
      })
  }
  useEffect(() => {
    if (isVerifyingMail) {
      timerRef.current = setInterval(() => {
        setOtpSecondsMail(
          otpSecondsMail === 0 && otpMinMail === 1
            ? 59
            : otpSecondsMail === 0 && otpMinMail === 0
              ? 0
              : otpSecondsMail - 1,
        );
        setOtpMinMail(otpSecondsMail === 0 && otpMinMail === 1 ? otpMinMail - 1 : otpMinMail);
        if (otpMinMail === 0 && otpSecondsMail === 0) {
          clearInterval(timerRef.current);
          setCanResendOtpMail(true);
        }
      }, 1000);
      return () => {
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
      };
    }
  }, [isVerifyingMail, otpSecondsMail, otpMinMail]);
  return <div className={`profile-edit-modal__form__group profile-edit-modal__form__email__container
  ${(!!verified_at || !!verifiedEmail) ? "profile-edit-modal__form__email__container--margin" : ""}`}>
    <label className="form-label">Email</label>
    <div style={{
      display: "flex",
      alignItems: "center"
    }}>
      <p className="form-input--disabled form-input--email form-input--secondary form-input profile-edit-modal__form__email">
        {email}
        {(!!verified_at || !!verifiedEmail) ? (
          <span className="profile-edit-modal__form__phone__verified">
            <img src={VerifiedGreen} alt="phone verified" style={{
              width: "16px"
            }} />
          </span>
        ) : (apiErrorTextMail || !verified_at) ?
          <span className="profile-edit-modal__form__phone__verified">
            <img src={ErrorRed} alt="phone error" style={{
              width: "16px"
            }} />
          </span>
          : null}
      </p>
      {(!!verified_at || !!verifiedEmail) ?
        <span className="profile-edit-modal__form__phone__verified">Your email is verified</span>
        :
        isVerifyingMail ? (
          <>
            <div style={{
              marginRight: "7px"
            }}>
              <input
                className="profile-edit-modal__form__phone__otp form-input form-input--secondary"
                onChange={(e) => setOtpMail(e.target.value.slice(0, 6))}
                value={otpMail}
                placeholder={texts.otp}
                maxLength="6"
                name="otpMail"
                type="number"
                style={{
                  border: otpInvalidMail ? "1px solid red" : null,
                  textAlign: "center"
                }}
              />
            </div>

            {canResendOtpMail ? (
              <button
                type="button"
                onClick={_getotpMail}
                className="profile-edit-modal__form__phone__btn"
              >
                Resend
              </button>
            ) : (
              <span
                className="profile-edit-modal__form__phone__btn--timer
                                        profile-edit-modal__form__phone__btn
                                        profile-edit-modal__form__phone__btn--disabled"
                style={{
                  textDecoration: "none",
                  color: "#171717"
                }}
              >
                0{otpMinMail}:{getTwoDigits(otpSecondsMail)}
              </span>
            )}
            <button
              type="button"
              onClick={_verifyOtpMail}
              className="profile-edit-modal__btn profile-edit-modal__btn--secondary"
              style={{
                opacity: otpMail.length < 6 ? "0.5" : null,
                padding: "0px",
                marginLeft: "16px"
              }}
              disabled={otpMail.length < 6}
            >
              Verify OTP
            </button>
          </>
        ) :
          !verified_at ?
            <button
              type="button"
              className="profile-edit-modal__btn profile-edit-modal__btn--secondary"
              onClick={() => {
                _getotpMail();
              }}
              style={{
                padding: "0px"
              }}
            >
              Verify Email
            </button>
            :
            null}
    </div>
    {apiErrorTextMail && (
      <p className="error-text profile-edit-modal__position-with-margin">
        {apiErrorTextMail}
      </p>
    )}
    {(!verified_at && !isVerifyingMail) && !apiErrorTextMail && (
      <p className="error-text profile-edit-modal__position-with-margin" >
        Email is not Verified
      </p>
    )}
      {isVerifyingMail && !apiErrorTextMail && (
      <p className="profile-edit-modal__form__phone__confirm-text">
         Please confirm if this email belongs to you by entering the OTP sent on your email.
      </p>
    )}
  </div>
};

function mapStateToProps(state) {
  return {
    token: state.login.token,
    user: state.user
  }
}

const mapDispatchToProps = {
  emailVerify,
  getCurrentUser,
  emailOtpVerification
}

Email.propTypes = {
  shouldCheckForNewProfile: PropTypes.bool,
  addToFilledItems: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(Email);
import React, { useEffect, useRef, useState } from 'react'
import Modal from './common/Modal'
import PropTypes from 'prop-types';
import moment from 'moment';
import { differenceInSeconds } from 'date-fns';

function TimingAndDailyLimitModal(props) {
    const { handleClose, handleProceed, data } = props;
    const {
        data: {
            block_type
            , duration,
            last_review_date, info
        }
    } = data;
    const { description,
        timer_end_text,
        timer_start_text,
        title,
        image_url
    } = info;
    const diff = differenceInSeconds(new Date(), new Date(last_review_date));
    const [remainiTime, setRemainTimne] = useState(duration - diff);
    const remainTimeDuration = moment.duration(remainiTime, 'seconds');


    useEffect(() => {
        document.body.style.overflow = "hidden"
        const interval = setInterval(() => {
            setRemainTimne((remain) => {
                if (remain === 0) { clearInterval(interval); return null };
                return remain - 1
            })
        }, 1000);
        return () => {
            clearInterval(interval);
            document.body.style.overflow = "auto"
        };
    }, []);

    const min = Math.floor(remainTimeDuration.asMinutes());
    const sec = Math.floor((remainTimeDuration.asSeconds()) % 60);
    const isCompleted = remainiTime === null;
    if (!data) return;
    return (
        <Modal
            title={title}
            showHeader={true}
            handleClose={handleClose}
            containerClassName="collaboration__view__details__timing-modal"
        >
            <div>
                <div>
                    <p className="text">{description}</p>
                </div>
                {
                    block_type === "review_timer" ?
                        <div className={`timer_div ${isCompleted && "green"}`}>
                            <p className="text">{isCompleted ? timer_end_text : timer_start_text}</p>
                            <div className="count_div">
                                <div>
                                    <div className="min_div">
                                        <p className="count-text">{min < 10 ? "0" : min.toString()[0]}</p>
                                        <p className="count-text">{min < 10 ? min.toString()[0] : min.toString()[1]}</p>
                                    </div>
                                    <p className="min-text">MIN</p>
                                </div>
                                <div>
                                    <p className="dot-text">:</p>
                                </div>
                                <div>
                                    <div className="min_div">
                                        <p className="count-text">{sec < 10 ? "0" : sec.toString()[0]}</p>
                                        <p className="count-text">{sec < 10 ? sec.toString()[0] : sec.toString()[1]}</p>
                                    </div>
                                    <p className="min-text">SEC</p>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="limit-img">
                            <img src={image_url} width="auto" height="160px" />
                        </div>
                }

                {
                    block_type === "review_timer" ?
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "36px" }}>
                            <button className={`review-button ${!isCompleted ? "disabled" : ""}`} disabled={!isCompleted} onClick={() => handleProceed(block_type)}>
                                Continue Reviewing
                            </button>
                        </div>
                        :
                        <div className="footer-div">
                            <p
                                className="collaboration__view__details__alergens_modal_text__cancel"
                                onClick={handleClose}
                            >
                                Cancel
                            </p>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <button className={`review-button`} onClick={() => handleProceed(block_type)}>
                                    Continue Reviewing
                                </button>
                            </div>
                        </div>
                }
            </div>
        </Modal>
    );
}
TimingAndDailyLimitModal.propTypes = {
    handleClose: PropTypes.func,
    title: PropTypes.string,
    handleProceed: PropTypes.func,
};

export default TimingAndDailyLimitModal;
import React from 'react';
import ReadMoreText from '../../containers/loggedIn/Feed/common/ReadMoreText';
import GoogleMapIcon from "../../../src/assets/images/rsvp/goggle-map.svg";
import VenueIcon from "../../../src/assets/images/rsvp/venue.svg";
import DateIcon from "../../../src/assets/images/rsvp/date.svg";
import TimeIcon from "../../../src/assets/images/rsvp/time.svg";
import LocationIcon from "../../../src/assets/images/rsvp/location.svg";
import { Link } from "react-router-dom";
import InvitationAction from './InvitationAction';
import InvitationTimer from './InvitationTimer';
import UserBar from '../../containers/loggedIn/Feed/common/UserBar';
import CompanyBar from '../../containers/loggedIn/Feed/common/CompanyBar';
import CloseIcon from "../../../src/assets/images/common/modals/close.svg";
import { format, parse } from 'date-fns';
import { handleInvitation } from '../collaborations/prManage/schedule/helper';
import { INVITATIONS_KEYS, invitationGAData } from '../collaborations/prManage/schedule/constants';

function InvitationListItem(props) {
    const { modal, handleCloseInvitationModal, data, handleUpdate, notificationViewDetail, inactive = false } = props;
    const { date, reporting_time } = data;
    const parsedTime = reporting_time ? parse(reporting_time, 'HH:mm:ss', new Date()) : '';
    const formattedTime = parsedTime ? format(parsedTime, 'hh:mm a') : '';
    const parsedDate = date ? parse(date, 'yyyy-MM-dd', new Date()) : "";
    const formattedDate = parsedDate ? format(parsedDate, 'dd MMM yyyy').toUpperCase() : '';


    const handleVenue = () => {
        const data = invitationGAData(INVITATIONS_KEYS.DIRECTION, INVITATIONS_KEYS.CLICK, INVITATIONS_KEYS.MY_INVITTAION)
        handleInvitation(data);
    }
    if (!data) return null;
    return (
        <div class={`invitation-item ${!!modal ? "no-margin" : ""}`}>
            <div className='invitation-item--header'>
                <div>
                    <Link to={`/collaborations/${data.collaboration && data.collaboration.id}/product-review`}
                        target="_blank" className='invitation-tasting'>{data.collaboration && data.collaboration.title}</Link>
                    {data.collaboration.company ? <CompanyBar company={data.collaboration.company} /> : data.collaboration.profile ? <UserBar profile={data.collaboration.profile} /> : null}
                </div>
                <div className="right">
                    {!data.seen_at && <p className='status'>New</p>}
                    {!!modal && <button className="close-btn" onClick={handleCloseInvitationModal}><img src={CloseIcon} /></button>}
                </div>
            </div>
            <div className='invitation-item--content'>
                <div className={`timer-div ${!!inactive ? "inactive" : ""}`}>
                    <div>
                        <p className="text"><img src={DateIcon} width="auto" height="16px" />{formattedDate}</p>
                        <p className="text"><img src={TimeIcon} width="auto" height="16px" />{formattedTime}</p>
                        <p className="text"><img src={LocationIcon} width="auto" height="16px" />{data.tasting_schedule_city.name}</p>
                    </div>
                    {parseInt(data.status) === 1 && !!modal && <InvitationTimer date={data.date} reportingTime={data.reporting_time} />}
                </div>
                <div className="address">
                    <span className={`location ${!!inactive ? "inactive" : ""}`}>
                        {data.tasting_schedule_city.location.title}
                    </span>
                    <div onClick={handleVenue}><a href={data.tasting_schedule_city.location.link} target="blank" rel="noopener noreferrer" className="venue"><img src={GoogleMapIcon} />Venue <img src={VenueIcon} /></a></div>
                </div>
                {data.description && <div className='description'>
                    <hr />
                    <p class={`more-text ${!!inactive ? "inactive" : ""}`}>
                        <ReadMoreText text={data.description} />
                    </p>
                </div>}
            </div>
            <div className={`invitation-item--footer ${!!modal ? "shadow" : ""}`}>
                <InvitationAction invitation={data} handleUpdate={handleUpdate} notificationViewDetail={notificationViewDetail} />
            </div>
        </div>
    )
}

export default InvitationListItem
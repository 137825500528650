// Importing images
import PRNotifiedImage from '../assets/images/collaborations/pr/manage/status/notified.svg';
import PRInProgressImage from '../assets/images/collaborations/pr/manage/status/inprogress.svg';
import PRCompletedImage from '../assets/images/collaborations/pr/manage/status/completed.svg';
import { decodeString, twoDecimal } from './helper';

// types of collaborations handled
export const typesOfCollaborations = {
  normal: 'collaborate',
  productReview: 'product-review',
};

export const COLLABORATION_GA_EVENTS_DETAILS_PAGE = "Single Collaboration Full View";
export const PR_GA_EVENTS_DETAILS_PAGE = "Single PR - Collaboration Full View";


// maximum number of products a
export const maxNumberOfPRProducts = 4;

// Function to get collaboration title
export const getCollabType = (type) => {
  return type === typesOfCollaborations.productReview ? 'PRODUCT REVIEW' : 'COLLABORATION';
};

// checking for types
export const collabTypeCheck = {
  isNormalCollaboration: (type = '') => type === typesOfCollaborations.normal,
  isPrivateReview: (type = '') => type === typesOfCollaborations.productReview,
};

// internal path of collaborations
export const collaborationInternalPath = {
  manage: (type, id) =>
    type === typesOfCollaborations.productReview
      ? `/collaborations/${id}/product-review/manage`
      : `/collaborations/${id}/manage`,
  view: (type, id) =>
    type === typesOfCollaborations.productReview
      ? `/collaborations/${id}/product-review`
      : `/collaborations/${id}`,
  edit: (type, id) =>
    type === typesOfCollaborations.productReview
      ? `/collaborations/${id}/product-review/edit`
      : `/collaborations/${id}/edit`,
  all: `/collaborations`,
  reports: (id) => `/collaborations/${id}/product-review/reports`,
  reportsDownload: (id) => `/collaborations/${id}/product-review/reports/downloads`,
};

// Function to get status of product review
export const getProductReviewStatusText = (status) =>
  status === 1 ? 'begin' : status === 2 ? 'resume' : 'complete';

// my collaboration states
export const myCollaborationState = [
  {
    title: 'Active',
    state: 1,
  },
  {
    title: 'Drafts',
    state: 4,
  },
  {
    title: 'Inactive',
    state: 5,
  },
  {
    title: 'Shown Interest',
    state: 6,
  },
];

export const myTastingState = [
  {
    title: 'Active',
    state: 1,
  },
  {
    title: 'Inactive',
    state: 5,
  },
  {
    title: 'Drafts',
    state: 4,
  },
  {
    title: 'Shown Interest',
    state: 6,
  },
];

// my collabroation filters
export const myCollaborationFilters = [
  {
    title: 'Product Reviews',
    state: typesOfCollaborations.productReview,
  },
  {
    title: 'Collaborations',
    state: typesOfCollaborations.normal,
  },
];
export const replaceNullData = function (arr) {
  return arr.length ? arr.filter((ele) => ele) : [];
};
export const replaceImageIndex = function (arr, index) {
  return arr.map((ele, currIndex) => {
    if (index === currIndex) {
      return null;
    }
    return ele;
  });
};

// Product
export const getPRStatusObject = (currentStatus) => {
  return currentStatus === 0
    ? {
      title: 'To be notified',
      image: '',
      color: '#D9222A',
    }
    : currentStatus === 1
      ? {
        title: 'Notified',
        image: PRNotifiedImage,
        color: '#e6a51c',
      }
      : currentStatus === 2
        ? {
          title: 'In Progress',
          image: PRInProgressImage,
          color: '#e6a51c',
        }
        : currentStatus === 3
          ? {
            title: 'Completed',
            image: PRCompletedImage,
            color: '#00a146',
          }
          : {
            title: '',
            image: '',
          };
};

// merging answer options with available options
export const mergePRReportsAnswerOptions = (answers, options) => {
  if (!answers && !options) return [];
  return options
    .map((option) => {
      let currentAnswerToMergeArr = answers.filter((answer) => answer.leaf_id === option.id),
        currentAnswerToMerge = currentAnswerToMergeArr.length
          ? currentAnswerToMergeArr[0]
          : {
            total: 0,
          };
      return { ...currentAnswerToMerge, ...option };
    })
    .reverse();
};

// collaboration closed or expired
export const isCollaborationClosedOrExpired = (collaboration) => {
  if (!collaboration || (collaboration && !collaboration.state)) return false;
  return collaboration.state === 'Expired' || collaboration.state === 'Close';
};

// method to get header weights
export const getWeightAvgForCollabProducts = (headerData, weights) => {
  try {
    let weightAvgObj = {
      title: 'Weighted Average',
      percentage: '100%',
    },
      headerWiseWeights = {},
      headerToChoose = headerData.slice(1),
      productWeights = {};
    // adding header wise wieghts
    weights.header_weight.forEach((weight) => {
      if (weight.header_type) {
        headerWiseWeights[decodeString(weight.header_type).toLowerCase()] = parseFloat(weight.weight);
      }
    });
    // adding products
    headerToChoose.forEach((header) => {
      let batches = header.summary && header.summary.batches ? header.summary.batches : null;
      if (batches) {
        [...batches]
          .sort((a, b) => a.batch_info.id - b.batch_info.id)
          .forEach((batch) => {
            const {
              batch_info: { id },
            } = batch,
              currentWeightedAvgValue =
                parseFloat(batch.overAllPreference) *
                parseFloat(headerWiseWeights[header.header_type.toLowerCase()] / 100);
            // checking if current product is already there or not
            if (!!productWeights[id]) {
              productWeights[id] = {
                ...productWeights[id],
                weightedAvg:
                  parseFloat(productWeights[id].weightedAvg) + parseFloat(currentWeightedAvgValue),
              };
            } else {
              productWeights[id] = {
                ...batch.batch_info,
                weightedAvg: currentWeightedAvgValue,
              };
            }
          });
      }
    });
    // adding products to the weighted average object
    let productKeys = Object.keys(productWeights);
    if (productKeys && !!productKeys.length) {
      weightAvgObj.products = [];
      productKeys.forEach((key) => {
        weightAvgObj.products.push({
          ...productWeights[key],
          // weightedAvg: twoDecimal(productWeights[key].weightedAvg)
          weightedAvg: toFixedTrunc(productWeights[key].weightedAvg, 2),
        });
      });
    }
    return weightAvgObj;
  } catch {
    return null;
  }
};
function toFixedTrunc(x, n) {
  const v = (typeof x === 'string' ? x : x.toString()).split('.');
  if (n <= 0) return v[0];
  let f = v[1] || '';
  if (f.length > n) return `${v[0]}.${f.substr(0, n)}`;
  while (f.length < n) f += '0';
  return `${v[0]}.${f}`;
}
// method to get text for product applicants food bill verifications
export const getCollabProductApplicantDocumentVerified = (status) =>
  status === 1
    ? { status, text: 'Verification Pending' }
    : status === 2
      ? { status, text: 'Accepted' }
      : status === 3
        ? { status, text: 'Rejected' }
        : { status, text: '' },
  prProductApplicantDocumentStatus = [0, 1, 2, 3];

// max number of file submission default
export const maxContentCollabFileNumber = 3;

// max number of users selected for collaboration group
export const maxCollabChatGroupUsers = 250;

// items per page for pagination in applicants list
export const collabApplicantsPerPage = 20;

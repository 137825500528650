import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CloseIcon from '../../../assets/images/common/modals/close.svg';
import { checkValidEmails } from '../../../utils/helper';
import { inviteContactsCancelToken,
    inviteContacts } from '../../../actions/profile/update';

const texts = {
    title: 'Invite a friend',
    name: 'Eg. Joe Rogan',
    email: 'joerogan@example.com',
    invalidEmailError: 'Please enter valid email.',
    errorMsg: 'Something went wrong. Please try again later.'
}

class InviteFriendsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            invalidEmail: false,
            disabledSubmit: false,
            error: false
        }
        this._handleInputChange = this._handleInputChange.bind(this);
        this._inviteFriends = this._inviteFriends.bind(this);
        this._toggleDisableSubmit = this._toggleDisableSubmit.bind(this);
    }

    componentWillUnmount() {
        if (inviteContactsCancelToken) inviteContactsCancelToken();
    }

    _inviteFriends(e) {
        e.preventDefault();
        this.setState({
            invalidEmail: false,
            error: false
        })
        const { name,
            email,
            disabledSubmit } = this.state;
        if (disabledSubmit) return;
        if ((!name.trim().length
            || !email.trim().length)) return;
        if (email && !checkValidEmails(email)) {
            this.setState({
                invalidEmail: true
            })
            return;
        }
        let dataToSend = {
            email: [{
                email,
                name
            }]
        }
        this._toggleDisableSubmit(true);
        const { token,
            inviteContacts,
            onClose } = this.props;
        inviteContacts(dataToSend,
            token)
            .then(val => {
                this._toggleDisableSubmit(false);
                if (val) {
                    onClose();
                } else {
                    this.setState({
                        error: true
                    })
                }
            })

    }

    _handleInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    _toggleDisableSubmit(disabled) {
        this.setState({
            disabledSubmit: typeof disabled !== 'undefined'
                ? disabled
                : !this.state.disabledSubmit
        })
    }

    render() {
        const { onClose } = this.props,
            { email, name, error, invalidEmail, disabledSubmit } = this.state,
            isDisabled = ((!email.trim().length
                || !name.trim().length)
                || (email && !checkValidEmails(email)));
        return (<>
            <div className="default-modal__header">
                <p className="default-modal__header__title">{texts.title}</p>
                <button className="default-modal__header__close" onClick={() => {
                    if (disabledSubmit) return;
                    onClose();
                }}>
                    <img src={CloseIcon} alt="close modal" />
                </button>
            </div>
            <div className="default-modal__content">
                <form onSubmit={this._inviteFriends}>
                    <div className="profile-form-modals__form">
                        <div className="profile-form-modals__form__group">
                            <label className="profile-form-modals__label">Name</label>
                            <input type="text"
                                name="name"
                                value={name}
                                autoFocus={true}
                                onChange={this._handleInputChange}
                                className="profile-form-modals__input"
                                required
                                placeholder={texts.name} />
                        </div>
                        <div className="profile-form-modals__form__group">
                            <label className="profile-form-modals__label">Email</label>
                            <input type="email"
                                name="email"
                                value={email}
                                onChange={this._handleInputChange}
                                required
                                className="profile-form-modals__input"
                                placeholder={texts.email} />
                            { invalidEmail && <p className="error-text">{texts.invalidEmailError}</p> }
                            { error && <p className="error-text">{texts.errorMsg}</p> }
                        </div>
                    </div>
                    <div className="profile-form-modals__footer profile-form-modals__footer--right">
                        <button type="submit"
                            disabled={isDisabled}
                            className={`profile-form-modals__btn
                            ${isDisabled
                                ? 'profile-form-modals__btn--disabled'
                                : ''}`}>Invite</button>
                    </div>
                </form>
            </div>
        </>)
    }
}

function mapStateToProps(state) {
    return {
        token: state.login.token
    }
}

InviteFriendsModal.propTypes = {
    onClose: PropTypes.func
}

const mapDispatchToProps = {
    inviteContacts
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteFriendsModal);

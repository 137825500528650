/* eslint-disable no-undef */
import { availableFeedCardPostTypes } from "./commonVariables";

require("dotenv").config();

const apiVersions = {
  versionOne: "v1",
  versionTwo: "v2",
};

// export const getBaseUrl = () => 'https://apidev.tagtaste.com/api/'

// export const getBaseUrl = () => 'https://api.tagtaste.com/api/';
export const getBaseUrl = () => process.env.REACT_APP_API_HOST;
export const ttfbBaseUrl = () => process.env.REACT_APP_TTFB_API_HOST;

export const CareerUrls = {
  postCareer: "career-mail",
};

export const loggedOutUrls = {
  login: "login", // POST
  register: "user/register", // POST
  forgotPassword: "password/email", // POST
  resetPassword: "password/reset", // POST
  socialLogin: (provider) => `social/login/${provider}`, // POST
  linkedInLogin: `social/login/auth/linkedin`, // POST
  emailVerify: (code) => `user/verify/email/${code}`, // GET
  loginOTPSend: "login/otp", //post
  verifyOTP: "login/otp/verify", //post
  logout: "logout", // post
  profileEmailVerify: "profile/verification/email", // post
  profileEmailOtpVerify: "profile/verification/email/otp" // post
};

export const collaborateUrls = {
  collaborate: "collaborate",
  getCollaborate: (id) => `${apiVersions.versionTwo}/collaborate/${id}`,
  getCollaborateChatGroups: (id, model_name) => `${apiVersions.versionTwo}/${model_name}/${id}/chats/groups`,
  getCollaborateTabs: (id) => `collaborate/${id}/manage/overview`,
  getCollaborateCreateGroup: `${apiVersions.versionOne}/chats/group`,
  getSendMessage: (chatId) => `${apiVersions.versionOne}/chats/${chatId}/messages`,
  getSimilarCollaborate: (id) => `similar/collaborate/${id}`,
  getCollaborateAppliants: (id) => `collaborate/${id}/applications`,
  rejectCollaborateApplicants: (profileId, collaborateId) =>
    `profiles/${profileId}/collaborate/${collaborateId}/reject`,
  getArchivedApplicants: (id) => `collaborate/${id}/archived`,
  getRejectedCollaborateApplicants: (id) => `collaborate/${id}/archived`,
  restoreRejectedApplicants: (profileId, collaborateId) =>
    `profiles/${profileId}/collaborate/${collaborateId}/approve`,
  create: (profileId, isCompany, companyId) =>
    isCompany
      ? `${apiVersions.versionOne}/profiles/${profileId}/companies/${companyId}/collaborate`
      : `${apiVersions.versionOne}/profiles/${profileId}/collaborate`,
  update: (collabId, profileId, isCompany, companyId) =>
    isCompany
      ? `${apiVersions.versionOne}/profiles/${profileId}/companies/${companyId}/collaborate/${collabId}`
      : `${apiVersions.versionOne}/profiles/${profileId}/collaborate/${collabId}`,
  apply: (collaborateId) => `collaborate/${collaborateId}/apply`,
  categories: `collaborate/categories`, // GEt
  types: `collaborate/types`, // GET
  brands: `brands`, // GET
  productTypes: `collaborate/productReview/types`, // GET
  globalQuestion: (id) =>
    id // GET
      ? `globalQuestion/${id}`
      : `globalQuestion`,
  uploadQuestion: (profileId, collabId, companyId) =>
    companyId //POST
      ? `${apiVersions.versionOne}/profiles/${profileId}/companies/${companyId}/collaborate/${collabId}/uploadQuestion`
      : `${apiVersions.versionOne}/profiles/${profileId}/collaborate/${collabId}/uploadQuestion`,
  tastingMethodology: `tastingMethodology`, //GET
  getBatches: (collabId) => `collaborate/${collabId}/batches`, // GET | POST
  getDemographyBatch: (collabId, batchId) => `collaborate/${collabId}/batch/${batchId}/demography`, // GET | POST
  singleBatch: (collabId, batchId) =>
    `collaborate/${collabId}/batches/${batchId}`, // GET | PATCH
  getBatchesColor: `batchesColor`, //GET
  updateScopeOfReview: (profileId, collabId, companyId) =>
    companyId // POST
      ? `${apiVersions.versionOne}/profiles/${profileId}/companies/${companyId}/collaborate/${collabId}/scopeOfReview`
      : `${apiVersions.versionOne}/profiles/${profileId}/collaborate/${collabId}/scopeOfReview`,
  getCloseReasons: `collaborateCloseReason`, //GET
  closeCollab: (profileId, collabId, companyId) =>
    companyId // POST
      ? `${apiVersions.versionOne}/profiles/${profileId}/companies/${companyId}/collaborate/${collabId}/close`
      : `${apiVersions.versionOne}/profiles/${profileId}/collaborate/${collabId}/close`,
  getCities: `getCities`,
  userBatches: (collaborateId) => `collaborate/${collaborateId}/userBatches`, // GET
  availableProducts: `userBatches`, // GET
  applyproduct: (collaborateId) => `collaborate/${collaborateId}/showInterest`,
  acceptInvitation: (collaborateId) =>
    `collaborate/${collaborateId}/acceptInvitation`, // POST
  rejectInvitation: (collaborateId) =>
    `collaborate/${collaborateId}/rejectInvitation`, // POST
  message: (collaborationId) => `collaborate/${collaborationId}/message`, // POST
  collaborationCities: (collabId) => `collaborate/${collabId}/cities`, // get
  addAddress: (collabId) => `collaborate/${collabId}/addAddress`, // POST | To update application data
  filters: (collabId) => `collaborate/${collabId}/applicantFilters`, // GET
  downloadExcel: (collabId) =>
    `${apiVersions.versionOne}/collaborate/${collabId}/collaborateApplicants/export`, // POST
  downloadArchivedApplicantsExcel: (collabId) =>
    `collaborate/${collabId}/archived/export`,
  mandatoryItems: `collaboration/mandatoryFields`, // GET
  getUserMandatoryItems: (collabId) =>
    `collaborate/${collabId}/mandatoryFields`, // GET
  acceptDocument: (collabId) => `collaborate/${collabId}/acceptDocument`, // POST
  rejectDocument: (collabId) => `collaborate/${collabId}/rejectDocument`, // POST,
  copy: (profileId, collabid) => `${apiVersions.versionOne}/profiles/${profileId}/collaborate/${collabid}/copy` // POST
};

// collaboration roles
export const collaborationRoles = {
  rolesForACollaboration: (profileId, companyId, collabId) =>
    companyId
      ? `${apiVersions.versionOne}/profiles/${profileId}/companies/${companyId}/collaborate/${collabId}/roles`
      : `${apiVersions.versionOne}/profiles/${profileId}/collaborate/${collabId}/roles`,
  assignRoleToAUser: (profileId, companyId, collabId) =>
    companyId
      ? `${apiVersions.versionOne}/profiles/${profileId}/companies/${companyId}/collaborate/${collabId}/assignRole`
      : `${apiVersions.versionOne}/profiles/${profileId}/collaborate/${collabId}/assignRole`,
  deleteRoleFromAUser: (profileId, companyId, collabId) =>
    companyId
      ? `${apiVersions.versionOne}/profiles/${profileId}/companies/${companyId}/collaborate/${collabId}/deleteRoles`
      : `${apiVersions.versionOne}/profiles/${profileId}/collaborate/${collabId}/deleteRoles`,
  getRole: (profileId, companyId, collabId) =>
    companyId
      ? `${apiVersions.versionOne}/profiles/${profileId}/companies/${companyId}/collaborate/${collabId}/getRole`
      : `${apiVersions.versionOne}/profiles/${profileId}/collaborate/${collabId}/getRole`,
};

// contest collaboration links
export const contestCollaborationLinks = {
  uploadContestFiles: "uploadContestFiles", // POST
  getApplicantsFiles: (profileId, companyId, collabId, applicantProfileId) =>
    companyId
      ? `${apiVersions.versionOne}/profiles/${profileId}/companies/${companyId}/collaborate/${collabId}/allSubmissions/${applicantProfileId}`
      : `${apiVersions.versionOne}/profiles/${profileId}/collaborate/${collabId}/allSubmissions/${applicantProfileId}`, // GET
  updateApplicantSubmissionState: (profileId, companyId, collabId) =>
    companyId
      ? `${apiVersions.versionOne}/profiles/${profileId}/companies/${companyId}/collaborate/${collabId}/updateSubmissionStatus`
      : `${apiVersions.versionOne}/profiles/${profileId}/collaborate/${collabId}/updateSubmissionStatus`, // POST
  documentUploadByApplicant: (collabId) =>
    `collaborate/${collabId}/contestSubmission`, // POST
  getSubmissions: (collabId) => `collaborate/${collabId}/getSubmissions`, // GET
};

// collaboration check
export const prCollaborationManage = {
  collaborateApplicants: (collabId) =>
    `${apiVersions.versionOne}/collaborate/${collabId}/collaborateApplicants`, // GET
  getRejectApplicants: (collabId) =>
    `${apiVersions.versionOne}/collaborate/${collabId}/getRejectApplicants`, // GET
  getInvitedApplicants: (collabId) =>
    `collaborate/${collabId}/getInvitedApplicants`, // GET
  getUnassignedApplicants: (collabId) =>
    `collaborate/${collabId}/getUnassignedApplicants`, // GET
  // reportSummary: (collabId) => `collaborate/${collabId}/reportSummary`, // GEt
  reports: (collabId, productId, headerId) =>
    `${apiVersions.versionTwo}/collaborate/${collabId}/batches/${productId}/headers/${headerId}/reports`, // GET
  getHeaderWeight: (collabId) => `${apiVersions.versionTwo}/collaborate/${collabId}/getHeaderWeight`, // POST
  storeHeaderWeight: (collabId) => `collaborate/${collabId}/storeHeaderWeight`, // POST
  reportPdf: (collabId, productId) =>
    `${apiVersions.versionTwo}/collaborate/${collabId}/batches/${productId}/reportPdf`, // POST
  getProductApplicants: (collabId, productId) =>
    `${apiVersions.versionOne}/collaborate/${collabId}/batches/${productId}`, // GET
  assignProduct: (collabId) => `collaborate/${collabId}/assignBatch`, //POST
  shortlistApplicants: (collabId) => `collaborate/${collabId}/shortlistPeople`, // POST
  rejectPeople: (collabId) => `collaborate/${collabId}/rejectPeople`, // POST
  inviteForReview: (collabId) => `collaborate/${collabId}/inviteForReview`, // POST
  removeFromProduct: (collabId) => `collaborate/${collabId}/removeFromBatch`, // POST
  rollbackApplicant: (collabId) => `collaborate/${collabId}/rollbackTaster`, // POST
  beginTasting: (collabId) => `collaborate/${collabId}/beginTasting`, // POST
  filters: (collabId) => `${apiVersions.versionTwo}/collaborate/${collabId}/dashboard/filters`, // POST
  productReportsFiters: (collabId, batchId) => `collaborate/${collabId}/batches/${batchId}/dashboard/filters`, //POST
  applicantsFilters: (collabId) => `${apiVersions.versionOne}/collaborate/${collabId}/applicantFilters`, // POST,
  productFiters: (collabId, batchId) => `${apiVersions.versionOne}/collaborate/${collabId}/batches/${batchId}/applicantFilters`, //POST
  reportFilteredQuestions: (collabId) => `collaborate/${collabId}/question-filters`, // GET
  getPRProfile: (collabId, productId) =>
    `collaborate/${collabId}/batches/${productId}/getPRProfile`, // GET
  prProductApplicantDocumentVerify: (collabId, productId) =>
    `collaborate/${collabId}/batches/${productId}/foodBillStatus`, // PUT
  anyOtherData: (collabId, productId, headerId, questionId, optionId) =>
    `${apiVersions.versionTwo}/collaborate/${collabId}/batches/${productId}/headers/${headerId}/questions/${questionId}/options${optionId ? `/${optionId}` : ""
    }`,// POST
  comments: (collabId, productId, headerId, questionId) =>
    `${apiVersions.versionTwo}/collaborate/${collabId}/batches/${productId}/headers/${headerId}/questions/${questionId}/comments`, // POST
  downloadRejectedApplicants: (collabId) =>
    `${apiVersions.versionOne}/collaborate/${collabId}/getRejectApplicants/export`, // POST
  downloadReports: (collabId) => `collaborate/${collabId}/skynet/reports`, // GET
  graphFilters: (collabId) => `${apiVersions.versionOne}/collaborate/${collabId}/graphfilters`, // GET
  graphHeader: (collabId) => `collaborate/${collabId}/graph/headers`, // GET
  graphHeaderResponse: (collabId, headerId) =>
    `${apiVersions.versionOne}/collaborate/${collabId}/header/${headerId}/graph`, // GET
  combinationGraphResponse: (collabId) =>
    `${apiVersions.versionOne}/collaborate/${collabId}/graph/combination`, // GET
  questionImages: (collabId, batchId, headerId, questionId) =>
    `${apiVersions.versionTwo}/collaborate/${collabId}/batches/${batchId}/headers/${headerId}/question/${questionId}/reports`, // POST,
  getCity: (collabId, cityType) => `collaborate/${collabId}/${cityType}`,
  getApplicantCity: (collabId, batchId, cityType) => `collaborate/${collabId}/batches/${batchId}/${cityType}`,
  schedule: {
    city: (collabId) => `collaborate/${collabId}/schedule/city`, // GET
    cityLocation: (collabId, cityId) => `collaborate/${collabId}/schedule/city/${cityId}/location`, // GET
    create: (collabId) => `collaborate/${collabId}/schedule`, //POST
    update: (collabId, scheduleId) => `collaborate/${collabId}/schedule/${scheduleId}`, //PATCH,
    delete: (collabId, scheduleId) => `collaborate/${collabId}/schedule/${scheduleId}`, //DELETE,
    allSchedules: (collabId) => `collaborate/${collabId}/schedule/list`,//POST
    details: (collabId, scheduleId) => `collaborate/${collabId}/schedule/${scheduleId}`,//GET
    getRemainingInvitees: (collabId, scheduleId) => `collaborate/${collabId}/schedule/${scheduleId}/remaining/invitee`, //GET
    filters: (collabId) => `collaborate/${collabId}/schedule/filters`,//POST
    detailFilters: (collabId, scheduleId) => `collaborate/${collabId}/schedule/${scheduleId}/filters`, // POST
    inviteesList: (collabId, scheduleId) => `collaborate/${collabId}/schedule/${scheduleId}/invitee`, // POST
    notify: (collabId, scheduleId) => `collaborate/${collabId}/schedule/${scheduleId}/notify`,//POST
    removeInvitee: (collabId, scheduleId) => `collaborate/${collabId}/schedule/${scheduleId}/invitee`,//DELETE
    addInvitees: (collabId, scheduleId) => `collaborate/${collabId}/schedule/${scheduleId}/invitee`,//PATCH
    inviteeTimeline: (collabId, scheduleId, inviteeId) => `collaborate/${collabId}/schedule/${scheduleId}/invitee/${inviteeId}/timeline`,//GET
  }
};

export const feedUrls = {
  // feed: `v2/ad_engine`,
  feed: `${apiVersions.versionTwo}/feed`,
};

export const landingUrls = {
  quickLinks: `landing/quick_links`,
  rightSideData: `landing/side_data`,
  landingPage: (platform) => `landing?platform=${platform}`,
  feed: `landing/feed`,
  topBar: `landing/top_data`,
};

export const feedCards = {
  single: (id) => `feed_card/${id}`,
};

export const hashtagLinks = {
  trending: `hashtag/trending`, // GET
  suggestions: `hashtag/suggestions`, // GET
  feed: `hashtag/feed`, // GET
};

export const likeUrls = {
  common: (postType, postId, isShared, shareId) =>
    isShared
      ? `share/${postType}/${shareId}/like`
      : `${postType}/${postId}/like`,
  photos: (postType, postId, userType, userId, isShared, shareId) =>
    isShared
      ? `share/${postType}/${shareId}/like`
      : `profiles/${userId}/photos/${postId}/like`,
};

// Common post urls and shoutout url
export const postUrls = {
  comments: (postType, postId) => `comments/${postType}/${postId}`, // GET | POST
  deleteComment: (commentId, postType, postId) =>
    `comments/${commentId}/${postType}/${postId}`, //DELETE
  sharePostComments: (postType, shareId) =>
    `comments/${postType}_share/${shareId}`, // GET | POST
  deleteAShowPostComment: (commentId, postType, shareId) =>
    `comments/${commentId}/${postType}_share/${shareId}`, // DELETE
  createShoutout: `shoutout`, //GET
  getShoutout: (postId) => `${apiVersions.versionTwo}/shoutout/${postId}`, //GET
  updateShoutout: (postId) => `${apiVersions.versionTwo}/shoutout/${postId}`, //POST | PATCH
  deleteAPost: (postType, postId, isShared, profileId, companyId) =>
    isShared
      ? `share/${postType}/${postId}`
      : postType === "photo"
        ? companyId
          ? `profiles/${profileId}/companies/${companyId}/photos/${postId}`
          : `profiles/${profileId}/photos/${postId}`
        : postType === "collaborate"
          ? companyId
            ? `profiles/${profileId}/companies/${companyId}/collaborate/${postId}`
            : `profiles/${profileId}/collaborate/${postId}`
          : `${postType}/${postId}`, // DELETE - Note: Doing this because of backend
  shareAPostOnFeed: (postType, postId) => `share/${postType}/${postId}`, // POST - share a post on feed
  getLikesForAPost: (postType, postId, isShared, sharedId) =>
    isShared
      ? `peopleLiked/${postType}Share/${sharedId}`
      : `peopleLiked/${postType}/${postId}`, //GET - get likes for a post
};

// Profile Urls
export const ProfileUrls = {
  profile: (profileId) => (profileId ? `profile/${profileId}` : "profile"),
  shortProfile: "short_profile",
  getUserProfile: (handle, profileId) =>
    handle ? `@${handle}` : `profile/${profileId}`,
  userProfileLink: (handle, profileId) =>
    handle ? `/@${handle}` : `/profile/${profileId}`,
  followers: (profileId) => `/profile/${profileId}/followers`, // internal page link
  following: (profileId) => `/profile/${profileId}/following`, // internal page link
  training: (profileId) => `profiles/${profileId}/trainings`,
  photos: (profileId) => `profiles/${profileId}/photos`,
  uploadDocuments: `profile/uploadDocument`,
  reviewCount: (profileId) => `profile/${profileId}/meta`,
  reviewsList: (profileId, publicTrue) =>
    publicTrue
      ? `profile/${profileId}/public/reviews`
      : `profile/${profileId}/private/reviews`,
  removePicture: (id) => `profile/${id}`,
  removeCompanyLogo: (profileId, companyId) =>
    `profiles/${profileId}/companies/${companyId}`,
  blockProfileLink: (type, id) => `block_account/${type}/${id}`,
};

// payment Urls
export const paymentUrls = {
  program: `payment/taster/program`,
  enroll: `payment/taster/enroll`,
  confirmPassword: `verify/password`,
  overview: `payment/overview`,
  filters: `payment/filters`,
  transactions: (transactionId) =>
    transactionId
      ? `payment/transaction/${transactionId}`
      : `payment/transaction`,
  raiseConcern: (transactionId) => `payment/issue/${transactionId}`,
  reviewConditions: (type, id) => `payment/review/rules/${type}/${id}`,
};

//chat Url
export const ChatUrls = {
  chat: `${apiVersions.versionOne}/chats`,
  markRead: (id) => `/${id}/markRead`,
  uploadFile: (id) => `/${id}/uploadFile`,
  markAsRead: (id) => `/${id}/markAsRead`,
  preview: "preview",
  search: "/search",
  sendDirectMessage: `${apiVersions.versionOne}/chatShareMessage`, // POST
};

export const taggingUrls = {
  profiles: (term) => `chats/profile/search?term=${term}`, // GET // only followers (except TT emp) // everyone (for TT emp)
  all: (term) => `comment/tagging?term=${term}`, // GET // everyone
};

// Photo urls
export const photoUrls = {
  globalUpload: `globalImageUpload`, // POST
  createPhotoPost: `${apiVersions.versionTwo}/photos`, // GET
  updatePhotoPost: (photoPostId) =>
    `${apiVersions.versionTwo}/photos/${photoPostId}`, // PATCH || GET
  uploadSelfie: (collabId) =>
    `public-review/products/${collabId}/uploadImageProduct`,
  // globalVideoUpload: (modelName) => `${modelName}/globalVideoUpload`,
};

// Video url
export const videoUrls = {
  globalUpload: (type) => `${type}/globalVideoUpload`, //POST

  //supported types are ['polling','surveys','quiz','collaborate'].
};

// Poll links
export const pollUrls = {
  createOrUpdatePoll: `polling`, // POST
  getPoll: (pollId) => `${apiVersions.versionTwo}/polling/${pollId}`, // GET
  updatePoll: (pollId) => `${apiVersions.versionTwo}/polling/${pollId}`, // POST
  voteAPoll: (pollId) => `polling/${pollId}/vote`, // POST
  deleteAnOption: (pollId) => `polling/${pollId}/deleteOptions`, // POST
  renew: (pollId) => `polling/${pollId}/renew`, // POST
};

// Socket links
const socketPath = process.env.REACT_APP_SOCKET_PATH;
export const socketLinks = {
  feed: `${socketPath}feed`, // query param token
  newChat: `${socketPath}newChat`, // socket for chat
  notifications: `${socketPath}notifications`, // query param token
};

// Preview link
export const previewLink = "preview",
  postPreview = (postType, postId, shareId) =>
    `preview/${postType}/${postId}${shareId ? "/shared/" + shareId : ""}`;

// Profile Update links
export const profileLinks = {
  jobProfiles: `profilesOccupations`,
  eatingHabits: `foodieType`,
  specialisations: `profilesSpecialization`,
  cuisines: `cuisine`,
  update: (profileId) => `profile/${profileId}`, // POST
  follow: `profile/follow`, //POST
  unfollow: `profile/unfollow`, // POST
  getFollowers: (profileId) => `profile/${profileId}/followers`, //GET
  getFollowing: (profileId) => `profile/${profileId}/following`, //GET
  getFeed: (profileId) => `feed/${profileId}`, //GET
  allergens: "profilesAllergens", // GET
  addAddress: (profileId) => `profiles/${profileId}/shippingAddress`, // POST
  singleAddress: (profileId, addressId) =>
    `profiles/${profileId}/shippingAddress/${addressId}`, // PATCH | DELETe
  invite: `invites`, //POST
  training: (profileId, trainingId) =>
    trainingId
      ? `profiles/${profileId}/trainings/${trainingId}` // PATCH | DELETE
      : `profiles/${profileId}/trainings`, // POST
  experiences: (profileId, experienceId) =>
    experienceId
      ? `profiles/${profileId}/experiences/${experienceId}` // PATCH | DELETE
      : `profiles/${profileId}/experiences`, // POST
  education: (profileId, educationId) =>
    educationId
      ? `profiles/${profileId}/education/${educationId}` // PATCH | DELETE
      : `profiles/${profileId}/education`, // POST
  phoneVerify: `profile/phoneVerify`, // POST
  newPhoneVerify: `profile/phone/send/otp`, // POST
  otpVerify: `profile/requestOtp`, // POST
  newOtpVerify: `profile/phone/verify/otp`, // POST
  addAllergens: `profile/addAllergens`, // POST
  premiumCompanies: `profile/premium`, // GET
  emailVerify: `profile/verify/email`, // POST
  myCollaborations: (profileId) =>
    `${apiVersions.versionOne}/profiles/${profileId}/collaborate`, // GET
  mySurvey: `surveys/my-list`, // GET
  myQuiz: `quiz/my-list`, // GET
  profile: (handle) => `/@${handle}`,
  all: `people`, // GET
  getAllPeopleBySpecialization: (id) =>
    `${apiVersions.versionOne}/search/specialization/${id}`,
};

// Filter links
export const filterLinks = {
  getFilterLinks: (postType) => `${postType}/filters`,
  getMoreFilter: (postType, filterType) =>
    `autocomplete/filter/${postType}/${filterType}`,
  applyFilters: (postType) => `filterSearch/${postType}`,
  people: (type) => `autocomplete/filter/people/${type}`, // GET
  companies: (type) => `autocomplete/filter/companies/${type}`, // GET
};

//settings link
export const SettingsUrl = {
  settings: "settings", //GET profile settings || POST
  companySettings: (companyId) => `settings/company/${companyId}`, //GET
  changePassword: "profile/password", //POST,
  changePasswordVerifyOtp: "profile/verification/otp", //POST,
  createPassword: "password/email", //POST,
  forgotChangeEmail: "forgot/send/otp", //POST,
  forgotChangeOtp: "forgot/verification/otp", //POST,
  forgotChangeReset: "forgot/reset", //POST,
};

//settings link
export const AccountManagementUrl = {
  options: "account_management/list", //GET account management list
  activity: (typeId) => `account_management/${typeId}/activity`, // GET
  reasons: (typeId) => `account_management/${typeId}/reasons`, // GET
  action: (typeId) => `account_management/${typeId}/action`, // POST
  sendOtp: (typeId) => `account_management/${typeId}/send_otp`, // POST
  verifyOtp: (typeId) => `account_management/${typeId}/verify_otp`, // POST
};

// shared postr
export const sharedPostLinks = (sharedId, postType, postId) =>
  postType === availableFeedCardPostTypes.survey
    ? `share/${postType}/${sharedId}/${postId}`
    : `${apiVersions.versionTwo}/share/${postType}/${sharedId}/${postId}`;

// notifications link
export const notificationsLinks = {
  all: `notifications`, // GET
  markAllAsRead: `notifications/markAllAsRead`, //POST
  markRead: (notificationId) => `notifications/read/${notificationId}`, // POST
  notificationSeen: `notifications/notification/seen`, // POST
  messageSeen: `notifications/message/seen`,
};

// product links
export const productLinks = {
  all: `public-review/products`, // GET
  allFilter: `public-review/products/filters`, //GET
  single: (productId) => `public-review/products/${productId}`, //GET
  headers: (productId) => `public-review/products/${productId}/headers`, //GET
  reviews: (productId) => `public-review/products/${productId}/reviews`, //GET | PARAMS Page
  reportSummary: (productId) =>
    `public-review/products/${productId}/reportSummary`, //GET
  headerReport: (productId, headerId) =>
    `public-review/products/${productId}/headers/${headerId}/reports`, //GET
  singleReview: (productId, reviewID) =>
    `public-review/products/${productId}/reviews/${reviewID}/comments`, //GET
  reviewComments: (productId, reviewID) =>
    `public-review/products/${productId}/reviews/${reviewID}/comments`, // GET | POST
  singleReviewComment: (productId, reviewID, commentId) =>
    `public-review/products/${productId}/reviews/${reviewID}/comments/${commentId}`, // DELETE
  foodShot: (productId) => `public-review/products/${productId}/food-shot`, // GET
  filters: (productId) => `public-review/products/${productId}/filters`, // GET
  reviewFilters: (productId) =>
    `public-review/products/${productId}/reviews-filter`, // GET
  anyOther: (productId, headerId, questionId, optionId) =>
    `public-review/products/${productId}/headers/${headerId}/questions/${questionId}/option${optionId ? `/${optionId}` : ""
    }`, // GET
  similarProducts: (productId) => `public-review/similarProducts/${productId}`, // GET
  getSample: (productId) => `public-review/products/${productId}/get-sample`,
  mandatoryItems: (productId) =>
    `public-review/products/${productId}/mandatoryFields`, // GET,
  startPublicReview: (productId) => `public-review/products/${productId}/startReview` // POST  

};

// company urls
export const companyUrls = {
  create: (profileId) => `profiles/${profileId}/companies`, //POST
  details: (companyId) => `companies/${companyId}`, // GET
  update: (profileId, companyId) =>
    `profiles/${profileId}/companies/${companyId}`, //POST | DELETE
  metaTypes: `meta/types`, // GET
  metaStatuses: `meta/statuses`, // GET
  follow: (profileId, companyId) =>
    `profiles/${profileId}/companies/${companyId}/follow`, //POST
  unfollow: (profileId, companyId) =>
    `profiles/${profileId}/companies/${companyId}/unfollow`, //POST
  info: (profileId, companyId, infoType, infoId) =>
    infoId
      ? `profiles/${profileId}/companies/${companyId}/${infoType}/${infoId}` // PATCH | DELETE
      : `profiles/${profileId}/companies/${companyId}/${infoType}`, // POST
  admin: (profileId, companyId, userId) =>
    userId
      ? `profiles/${profileId}/companies/${companyId}/users/${userId}` //DELETE
      : `profiles/${profileId}/companies/${companyId}/users`, //GET | POST
  companyInternalPage: (companyId) => `companies/${companyId}`,
  getActivity: (companyId) => `feed/companies/${companyId}`, //GET
  photos: (profileId, companyId) =>
    `profiles/${profileId}/companies/${companyId}/photos`, //GET
  followers: (profileId, companyId) =>
    `profiles/${profileId}/companies/${companyId}/followers`, // GET
  getUserWithoutAdmin: (companyId) =>
    `companies/${companyId}/getUserWithoutAdmin`,
  all: `companies`,
  makeSuperAdmin: (companyId) => `company/${companyId}/update_ownership`, // POST
};

export const tastingUrls = {
  collaboration: {
    tastingHeaders: (collaborateId) => `collaborate/${collaborateId}/headers`, //GET
    getTastingDataByHeaderId: (collaborateId, headerId) =>
      `collaborate/${collaborateId}/headers/${headerId}`, //GET
    getNestedOptionsVal: (collabId, headerId, questionId) =>
      `collaborate/${collabId}/headers/${headerId}/question/${questionId}`, // GET
    search: (collabId, headerId, questionId) =>
      `collaborate/${collabId}/headers/${headerId}/question/${questionId}/search`, //GET
    submitAnswers: (collaborateId, headerId) =>
      `collaborate/${collaborateId}/headers/${headerId}`, //POST
    getMandatoryFeilds: `mandatoryField/product-review`,
    nestedParentSearch: (collabId, headerId, questionId) =>
      `collaborate/${collabId}/headers/${headerId}/question/${questionId}/searchNestedParent`, // GET,
    startPrivateReview: (collabId, batchId) =>
      `collaborate/${collabId}/batches/${batchId}/startReview`,
    collabSubmissions: (collabId, batchId, profileId) =>
      `collaborate/${collabId}/batches/${batchId}/profile/${profileId}/submission_status`, // GET
    collabFlagLogSubmissions: (collabId, batchId, profileId) =>
      `collaborate/${collabId}/batches/${batchId}/profile/${profileId}/flag`, // GET,
    updateDemography: (collabId) =>
      `collaborate/${collabId}/batches/demography`, // PATCH,
  },
  product: {
    tastingHeaders: (collaborateId) =>
      `public-review/products/${collaborateId}/headers`, //GET
    getTastingDataByHeaderId: (collaborateId, headerId) =>
      `public-review/products/${collaborateId}/headers/${headerId}`, //GET| POST
    submitAnswers: (collaborateId, headerId) =>
      `public-review/products/${collaborateId}/headers/${headerId}/review`, //POST
    getNestedOptionsVal: (collaborateId, headerId, questionId) =>
      `public-review/products/${collaborateId}/headers/${headerId}/question/${questionId}`,
    search: (collaborateId, headerId, questionId) =>
      `public-review/products/${collaborateId}/headers/${headerId}/question/${questionId}/search`,
    nestedParentSearch: (productId, headerId, questionId) =>
      `public-review/products/${productId}/headers/${headerId}/question/${questionId}/searchNestedParent`, // GET
  },
};

export const previewUrls = {
  getPreviewDetails: (collabId) => `preview/questionnaire/${collabId}/detail`,
  getPreviewHeaders: (collabId) => `preview/questionnaire/${collabId}/headers`, // GET
  getPreviewHeaderQuestions: (collabId, headerId) => `preview/questionnaire/${collabId}/headers/${headerId}/questions`, // GET
  getPreviewNestedOptions: (collabId, headerId, questionId) => `preview/questionnaire/${collabId}/headers/${headerId}/question/${questionId}`, // GET
  searchPreviewNestedOptions: (collabId, headerId, questionId) => `preview/questionnaire/${collabId}/headers/${headerId}/question/${questionId}/search`, // GET
  sharePreview: (collabId) => `preview/questionnaire/${collabId}/share`, // POST 
  verifyPreviewOTP: (collabId) => `preview/questionnaire/${collabId}/verify-otp`, // POST 
};

// social links
export const socialLinks = {
  facebook: `https://www.facebook.com/tagtastefoods`,
  instagram: `https://www.instagram.com/tagtaste/`,
  twitter: `https://twitter.com/tagtaste`,
};

export const publicViewLinks = {
  post: (postType, postId) => `public/${postType}/${postId}`, //GET
  profileHandle: (handle) => `public/profile/@${handle}`, // GET
  profileDataById: (profileId) => `public/profile/${profileId}`, //GET
  foodShot: (productId) => `public/product/${productId}/food-shot`, // GET
  sharedPost: (sharedId, postType, postId) =>
    `public/${postType}/${postId}/shared/${sharedId}`,
};

// faq links
export const faqLinks = {
  all: `allCategories`, // GET
  categoryQuestions: (categoryId) => `categoriesQuestionAnswer/${categoryId}`, // GET
};

// ttfb leads
export const ttfbLinks = {
  leadStatus: `lead/status`, // GET
  lead: `lead`, // POST
  singleLead: (leadId, isComment) =>
    `lead/${leadId}${isComment ? "/comment" : ""}`, // GET | PATCH | DELETE
  logs: (leadId) => `lead/${leadId}/logs`, // GET
  singleLog: (logId) => `logs/${logId}`, // single log api
  getDistributors: `distributors`, // GET
  getManufacturers: `manufacturers`, // GET
  getPartners: `partners`, // GET
  getProducts: `products/nlp`, // GET
  activeProducts: `products/nlp/active`, //GET
  singleProduct: (productId) => `product/${productId}/overview`, // GET
  productLeads: (productId) => `lead/product/${productId}`, // GET
  productLeadById: (productId, leadId) => `lead/product/${productId}/${leadId}`, // GET
  sideBar: `landing/sidebar`, // GET
  profile: `profile`, // GET
  typeOverview: (overviewType) => `${overviewType}/overview`, // GET (landing, partner, manufacturer, distributor)
  searchByType: (type) => `search/${type}`, // GET
  consistency: {
    products: {
      headers: (groupId, productId, batchId) =>
        `${groupId}/consistency/${productId}/headers/${batchId}`, // GET
      headerQuestions: (groupId, productId, batchId, headerId) =>
        `${groupId}/consistency/${productId}/headers/${headerId}/${batchId}`, // GET | POST
      nestedOptions: (groupId, productId, headerId, questionId) =>
        `${groupId}/consistency/${productId}/headers/${headerId}/question/${questionId}`, // QUESTION
      search: (groupId, productId, headerId, questionId) =>
        `${groupId}/consistency/${productId}/headers/${headerId}/question/${questionId}/search`, // QUESTION
    },
    questionnaire: (groupId) => `${groupId}/consistency/questionnaire`,
    users: (groupId) => `${groupId}/consistency/users`,
    overview: (groupId) => `${groupId}/consistency/overview`,
    deeplink: (trioCode) => `trio/${trioCode}`,
    overviewProducts: (groupId) =>
      `${groupId}/consistency/overview/ongoing-products`,
    batchFields: (groupId) => `${groupId}/consistency/batchfields`,
    product: (groupId) => `${groupId}/consistency/product`,
    deleteProduct: (groupId, productId) =>
      `${groupId}/consistency/product/delete/${productId}`,
    batch: (groupId, productId) => `${groupId}/consistency/${productId}/batch`,
    trio: (groupId, productId, batchId) =>
      `${groupId}/${productId}/batch/${batchId}/trio`,
    deleteBatch: (groupId, productId, batchId) =>
      `${groupId}/consistency/${productId}/batch/delete/${batchId}`,
    reports: {
      batchlist: (groupId, productId) =>
        `${groupId}/${productId}/reports/batchlist`,
      filters: (groupId, productId) =>
        `${groupId}/${productId}/reports/filterlist`,
      headers: (groupId, productId) =>
        `${groupId}/${productId}/reports/headers`,
      summaryReport: (groupId, productId) =>
        `${groupId}/${productId}/reports/summary`,
      batchReport: (groupId, productId, batchId, headerId) =>
        `${groupId}/${productId}/reports/batch/${batchId}/header/${headerId}`,
      anyOther: (groupId, productId, batchId, headerId, questionId, option) =>
        `${groupId}/${productId}/reports/batch/${batchId}/header/${headerId}/question/${questionId}/option/${option}`,
      comments: (groupId, productId, batchId, headerId, questionId) =>
        `${groupId}/${productId}/reports/batch/${batchId}/header/${headerId}/question/${questionId}/comments`,
      weightReport: (groupId, productId, batchId) =>
        `${groupId}/${productId}/reports/batch/${batchId}/getHeaderWeightReport`,
      storeWeightReport: (groupId, productId, batchId) =>
        `${groupId}/${productId}/reports/batch/${batchId}/storeHeaderWeight`,
    },
  },
};

export const invitationsLinks = {
  invitationsList: (profileId) => `profiles/${profileId}/tasting/invitation`, // GET
  invitationUpdate: (collabId, profileId) => `profiles/${profileId}/tasting/invitation/${collabId}`, // POST
  collabInvitations: (collabId, profileId) => `profiles/${profileId}/collaborate/${collabId}/invitation`, // GET
};

//review links
export const reviewLinks = {
  all: `${apiVersions.versionTwo}/public-review/explore`,
  collections: (collectionId) =>
    `${apiVersions.versionTwo}/collection/${collectionId}`, // GET
  collectionElements: (collectionId) =>
    `${apiVersions.versionTwo}/collection/${collectionId}/elements`, //GET
  campusConnect: `${apiVersions.versionTwo}/campus-connect`,
};

//see-all page
export const seeAllLinks = {
  polls: `${apiVersions.versionOne}/filterSearch/polls`,
  privateReviews: `${apiVersions.versionOne}/filterSearch/product-review`,
  collaborations: `${apiVersions.versionOne}/filterSearch/collaborate`,
};

// report flags
export const reportLinks = {
  byType: (type) => `report-type/${type}`,
  reportContent: `report-content`,
  reportUser: `report-user`,
};

// explore link
export const exploreLinks = {
  index: `${apiVersions.versionTwo}/search/explore`,
};

// email links
export const emailLinks = {
  reportEmail: (emailName) => `mail/${emailName}`,
};

// common links
export const commonLinks = {
  fileUpload: `globalFileUpload`, // POST
  captcha: 'captcha' // POST - this will verify captcha for h-captcha
};

// survey links
export const surveyLinks = {
  questionTypes: `surveys/questions-list`, // GET
  single: (id) => `surveys/${id}`, // GET
  getSurveyChatGroups: (id, model_name) => `${model_name}/${id}/chats/groups`,
  create: `surveys`, // POST
  similar: (id) => `surveys/similar/${id}`, // GET
  save: `surveys/save-survey`,
  copy: (id) => `surveys/${id}/copy`, // POST
  answers: (id) => `surveys/${id}/answers`, // GET
  reports: (id) => `v1/surveys/reports/${id}`, // POST
  respondents: (id) => `v1/surveys/respondents/${id}`, //POST
  singeUser: (id, userId) => `surveys/user-report/${id}/${userId}`,
  singeUserWithSection: (id, userId, sectId) =>
    `surveys/user-report/${id}/${userId}/section/${sectId}/`, // GET
  textAnswers: (id, questionId, optionId) =>
    `v1/surveys/text-answers/${id}/${questionId}/${optionId}`, // POST
  mediaFiles: (id, questionId, type) =>
    `v1/surveys/media-list/${id}/${questionId}/${type}`, // POST
  reportDownload: (id) => `v1/surveys/download-reports/${id}`, // POST
  closeReaons: `surveys/close-reasons`,
  close: (id) => `surveys/close/${id}`, // POST
  getMandatoryFields: (id) =>
    id
      ? `surveys/mandatory-fields/${id}` // GET
      : `surveys/mandatory-fields`, // GET
  surveyApplicantsStateSave: (id) => `surveys/survey-applicant/${id}`, // POST
  reportFilters: (id) => `${apiVersions.versionTwo}/surveys/filters-list/${id}`, // GET
  reportFilteredQuestions: (id) => `v1/surveys/filters-list/${id}/questions`, // POST
  filters: (id) => `${apiVersions.versionOne}/surveys/${id}/applicantFilters`, // POST
  applicants: (id) => `${apiVersions.versionOne}/surveys/${id}/applicants`, // POST
  invitedApplicants: (id) => `surveys/${id}/getInvitedApplicants`, // GET
  rejectedApplicants: (id) => `${apiVersions.versionOne}/surveys/${id}/getRejectApplicants`, // POST
  showInterest: (id) => `surveys/${id}/showInterest`, // GET
  beginSurvey: (id) => `surveys/${id}/beginSurvey`, // POST
  downloadList: (id) => `${apiVersions.versionOne}/surveys/${id}/applicants/export`, // POST
  downloadRejectedList: (id) => `${apiVersions.versionOne}/surveys/${id}/applicants/rejected/export`, // POST
  inviteUserList: (id) => `surveys/${id}/invite/profiles`, // GET
  inviteUser: (id) => `surveys/${id}/inviteForReview`, // POST
  message: (id) => `surveys/${id}/message`, // POST
  rollbackApplicant: (id) => `surveys/${id}/rollback`, // POST
  rejectApplicant: (id) => `surveys/${id}/rejectApplicant`, // POST
  shortlistApplicant: (id) => `surveys/${id}/shortlistApplicant`, // POST
  getSubmissionList: (id, profileId) =>
    `surveys/${id}/applicants/${profileId}/submission_timeline`, // GET
  getFlagLogList: (id, profileId) =>
    `surveys/${id}/applicants/${profileId}/flag`, // GET  
  getSurveyTiming: (id) => `surveys/${id}/startSurvey`, // POST,
  surveyFlagLogSubmissions: (surveyId, submissionId) =>
    `surveys/${surveyId}/submission/${submissionId}/flag`, // POST
  getCity: (id, cityType) => `surveys/${id}/${cityType}`,
};
export const quizLinks = {
  single: (id) => `quiz/${id}`, // GET - for getting quiz details, PATCH - for updating quiz, DELETE - for deleting quiz
  create: `quiz`, // POST
  submit: (id) => `quiz/${id}/submit`, // POST
  score: (id) => `quiz/${id}/result`, // GET
  close: (id) => `quiz/close/${id}`, // POST
  closeReasons: `quiz/close-reasons`, // GET
  answers: (id) => `quiz/getAnswers/${id}`, // GET,
  similar: (id) => `quiz/similar/${id}`, // GET
  reports: (id) => `quiz/reports/${id}`, // GET
  selfReport: (id, userId) => `quiz/self-quiz-response/${id}/${userId}`, // GET
  respondents: (id) => `quiz/respondents/${id}`, //GET
  singleUser: (id, userId) => `quiz/user-responses/${id}/${userId}`, // GET
  textAnswers: (id, questionId, optionId) =>
    `quiz/text-answers/${id}/${questionId}/${optionId}`, // GET
  reportDownload: (id) => `quiz/download-reports/${id}`, // POST
  reportFilters: (id) => `quiz/filters-list/${id}`, // POST
};

// donation links
export const donationLinks = {
  getOrganisations: 'donation/organisations', //GET
  postDonation: 'profile/donation/update', // POST
}

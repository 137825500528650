import React, { useEffect, useState } from 'react';
import { checkIfPromptToShow, getProfileItemsToShow, sendProfilePromptGA, setPromptDataInLocalStorage } from '../utils/sessionHelper';
import PromptModals from '../components/modals/common/PromptModals';
import { PROFILE_COMPLETION_GA } from '../components/modals/profilePrompts/constants';
import { hideProfilePromptModal, showProfilePromptModal } from '../actions/common/modals';
import { connect } from 'react-redux';

const withPromptStructure = (WrappedComponent) => {
    function WithCommonStructure(props) {

        const session = JSON.parse(localStorage.getItem("session")) || null;
        const profileItemsToBeFilled = getProfileItemsToShow(props.profile);
        const [isLoaded, setLoaded] = useState(false);

        const handlePrompt = async (data) => {
            if (!data) {
                handleClose();
                return;
            }
            if (!props.profile || (props.profile && !props.profile.prompts_active)) return false;
            const { GAData, delay, ...rest } = data;
            try {
                const { screenName, modelName, promptType } = GAData;

                const shouldPromptShow = await checkIfPromptToShow({
                    profileItemsToBeFilled,
                    promptType,
                    prompts_active: props.profile.prompts_active,
                    delay
                });

                if (shouldPromptShow) {
                    const sendGA = (event) => {
                        sendProfilePromptGA(event, screenName, modelName, promptType, profileItemsToBeFilled);
                    };

                    props.showProfilePromptModal({
                        handleGAEvents: sendGA,
                        onClose: handleClose,
                        onUpdateSuccess: handleUpdateSuccess,
                        profileItemsToBeFilled,
                        promptToShowIndex: session.promptToShowIndex || 0,
                        ...rest
                    });

                    sendGA(PROFILE_COMPLETION_GA.VIEW);
                } else {
                    if (data.continueButtonAction) {
                        data.continueButtonAction();
                    }
                }
            } catch (error) {
                if (data.continueButtonAction) {
                    data.continueButtonAction();
                }
            }
        };

        const handleUpdateSuccess = (isPromptUpdated) => {
            if (isPromptUpdated) {
                session.isPromptShownInCurrentSession = true;
                session.promptToShowIndex = session.promptToShowIndex === profileItemsToBeFilled.length ? 0 : session.promptToShowIndex;
                setPromptDataInLocalStorage(session);
            }

        }

        const handleClose = (isPromptDiscard) => {
            if (isPromptDiscard) {
                session.promptToShowIndex = (session.promptToShowIndex + 1) % profileItemsToBeFilled.length;
                session.isPromptShownInCurrentSession = true;
                setPromptDataInLocalStorage(session);
            }
            props.hideProfilePromptModal();
        }



        useEffect(() => {
            if (!isLoaded) {
                if (props.profile && props.profile.longProfileLoaded) {
                    setLoaded(true);
                }
            }
            return () => {
                handleClose();
            }
        }, [, props.profile]);
        if (!isLoaded) return false;
        return (
            <>
                <PromptModals />
                <WrappedComponent {...props} handlePrompt={handlePrompt} />
            </>
        );
    }

    const mapStateToProps = (state) => {
        return {
            profile: state.user.user.profile,
        };
    };
    const mapDispatchToProps = {
        showProfilePromptModal,
        hideProfilePromptModal
    };

    return connect(mapStateToProps, mapDispatchToProps)(WithCommonStructure);
};

export default withPromptStructure;

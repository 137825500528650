import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "../../../assets/images/common/modals/close.svg";

function Modal(props) {
  const {
    showHeader,
    handleClose,
    disabledClose,
    className,
    contentClassName,
    containerClassName,
    isHeaderHTML,
    title,
    isHeaderComponent = false,
    headerComponent = "",
  } = props;

  const setHeader = () => {
    let output = <p className="default-modal__header__title">{title || ""}</p>;
    if (isHeaderHTML) {
      output = (
        <p
          className="default-modal__header__title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      );
    } else if (isHeaderComponent) {
      output = headerComponent;
    }
    return output;
  };

  return (
    <div className={`default-modal ${className || ""}`}>
      <div className="default-modal__backdrop">
        <div
          className={`default-modal__container ${
            containerClassName ? containerClassName : ""
          }`}
        >
          {showHeader && (
            <div className="default-modal__header">
              {setHeader()}
              <button
                type="button"
                className="default-modal__header__close"
                onClick={disabledClose ? () => {} : handleClose}
              >
                <img src={CloseIcon} alt="close modal" />
              </button>
            </div>
          )}
          <div className={`default-modal__content ${contentClassName || ""}`}>
            <div>
            {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  showHeader: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
  disabledClose: PropTypes.bool,
  isHeaderHTML: PropTypes.bool,
  isHeaderComponent: PropTypes.bool,
  headerComponent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default Modal;

import React from "react";
import { Action, DateTime, SerialNumber } from "./ScheduleTableElements";
import styled from "styled-components";
import { screenBreakpoints } from "../../../../utils/commonVariables";


const columnsFn = () => {
  return [
    {
      Header: 'Sr.No.',
      accessor: 'invitee__serial',
      width: 70
    },
    {
      Header: 'Date & Reporting Time',
      Cell: ((row) => <DateTime row={row} />),
      width: 200,
    },
    {
      Header: 'Tasting City',
      accessor: 'tasting_schedule_city',
      width: 150,
    },
    {
      Header: 'Location',
      accessor: 'tasting_schedule_city.location',
      width: 320,
    },

    {
      Header: 'Invited Applicants',
      accessor: 'invited_count',
      width: 150
    },
    {
      Header: 'Confirmed Applicants',
      accessor: 'confirmed_count',
      width: 165,
    },
    {
      Header: '',
      accessor: 'invitee__action',
      width: 150,
    },
  ];
};

const Styles = styled.div`
  padding-top: 10px;

  .table {
    border: 1px solid #ddd;
    min-width: 800px;
    overflow-x:auto;

    @media screen and (max-width: ${screenBreakpoints.mob}px) {
      min-width: auto;
    }


    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }
   
    .th,
    .td {
      padding: 24px 8px !important;
      border-bottom: 1px solid #f2f2f2;
      background-color: #fff;

        :last-child {
            border-right: 0;
        }
        :first-child{
            padding-left:16px !important;
        }
    }
    .th{
        background-color: #f2f2f2;
        :first-child{
          border-top-left-radius:16px;
        }
        :last-child{
          border-top-right-radius:16px;
        }
    }
  }
`;

const inviteeListcolumnsFn = (isMobile) => {
  return [
    {
      Header: 'APPLICANTS',
      accessor: 'invitation_details_applicant',
      sticky: isMobile ? '' : 'left',
      width: 230,
      canSelectAll: true,
      hasShadow: isMobile ? false : true, // column shadow 
    },
    {
      Header: 'USER TYPE',
      accessor: 'invitation_details_usertype',
      width: 100,
    },
    {
      Header: 'GENDER',
      accessor: 'invitation_details_gender',
      width: 100,
    },
    {
      Header: 'AGE',
      accessor: 'invitation_details_age',
      width: 100,
    },
    {
      Header: 'GENERATION',
      accessor: 'invitation_details_generation',
      width: 100,
    },
    {
      Header: 'TASTING CITY',
      accessor: 'invitation_details_tastingcity',
      width: 150,
    },
    {
      Header: 'NO. OF NOTIFY COUNT',
      accessor: 'invitation_details_notifycount',
      width: 160,
    },
    {
      Header: '',
      accessor: 'invitation_details_action',
      width: 50,
    },
  ];
};

const addInviteeListColumnsFn = () => {
  return [
    {
      Header: 'APPLICANTS',
      accessor: 'invitation_details_applicant',
      width: 300,
      canSelectAll: true,
    },
    {
      Header: 'TASTING CITY',
      accessor: 'invitation_details_tastingcity',
      width: 255,
    },
  ];
}

const InviteeListStyles = styled.div`
  padding-top: 10px;

  .table {
    border: 1px solid #ddd;
    overflow-x:auto;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 8px;
      border-bottom: 1px solid #f2f2f2;
      background-color: #fff;

      &.with-shadow{
        box-shadow:1px 5px 7px 0px rgb(0 0 0 / 10%);
      }

      :last-child {
        border-right: 0;
      }
      :first-child {
        clip-path: inset(0px -10px 0px -10px);
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }
    &.remove_sticky {
      overflow: auto;
      max-height:calc(100vh - 200px);
      .header {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        box-shadow: 1px 5px 7px 0px rgb(0 0 0 / 10%);
      }

      .body {
        position: relative;
        z-index: 0;
        margin-top: 2px;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        // box-shadow: 1px 5px 7px 0px rgb(0 0 0 / 10%);
        // box-shadow: 0px 0px 8px 0px rgba(204,204,204,0.6);
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
    &.sticky {
      overflow: auto;
      max-height: ${props => props.isAdd ? 'calc(100vh - 335px)' : 'calc(100vh - 200px)'};
      .header {
        position: sticky;
        z-index: 1;
        width: fit-content;
         top: 0;
        &.with-shadow{
         box-shadow: 1px 5px 7px 0px rgb(0 0 0 / 10%);
        }
      }

      .body {
        position: relative;
        z-index: 0;
        margin-top: 2px;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        // box-shadow: 1px 5px 7px 0px rgb(0 0 0 / 10%);
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`;


const getTypesOfApplicants = (typesList) => {
  if (typesList) {
    const typesDataWithCount = typesList.map((each) => {
      return { ...each, type: each.title.toLowerCase() }
    });
    return typesDataWithCount;
  }
  return [
    { type: "invited", title: "Invited" },
    {
      type: "accepted",
      title: "Accepted",
    },
    {
      type: "pending",
      title: "Pending",
    },
    {
      type: "declined",
      title: "Declined",
    },
  ];
};


const defaultAllScheduleParams = {
  searchInput: '',
  page: 1,
  sortBy: ''
}
const defaultScheduleDetailsParams = {
  type: "invited",
  detailsSearchInput: '',
}

const INVITATIONS_KEYS = {
  ACCEPT: "rsvp_invite_accept",
  REJECT: "rsvp_invite_reject",
  ADD_CALENDAR: "rsvp_add_calendar",
  DIRECTION: "rsvp_get_direction",
  INFO_DETAILS: "rsvp_info_details",
  VIEW: "view",
  CLICK: "click",
  LANDING_PAGE: "landing page",
  MY_INVITTAION: "my invitations",
  TASTING_DETAILS: "tasting details",
  BELL_NOTIFICATIONS: "bell notifications"
}

const INVITATIONS_GA = {
  [INVITATIONS_KEYS.ACCEPT]: "rsvp_invite_accept",
  [INVITATIONS_KEYS.REJECT]: "rsvp_invite_reject",
  [INVITATIONS_KEYS.ADD_CALENDAR]: "rsvp_add_calendar",
  [INVITATIONS_KEYS.DIRECTION]: "rsvp_get_direction",
  [INVITATIONS_KEYS.INFO_DETAILS]: "rsvp_info_details"
};

const INVITATIONS_GA_ACTION = {
  [INVITATIONS_KEYS.VIEW]: "view",
  [INVITATIONS_KEYS.CLICK]: "click"
}

const INVITATIONS_GA_LABEL = {
  [INVITATIONS_KEYS.LANDING_PAGE]: "landing page",
  [INVITATIONS_KEYS.MY_INVITTAION]: "my invitations",
  [INVITATIONS_KEYS.TASTING_DETAILS]: "tasting details",
  [INVITATIONS_KEYS.BELL_NOTIFICATIONS]: "bell notifications"
}

const invitationGAData = (event, action, label) => {
  return {
    event: INVITATIONS_GA[event],
    action: INVITATIONS_GA_ACTION[action],
    label: INVITATIONS_GA_LABEL[label]
  }
}


export { columnsFn, Styles, InviteeListStyles, inviteeListcolumnsFn, addInviteeListColumnsFn, getTypesOfApplicants, defaultAllScheduleParams, defaultScheduleDetailsParams, INVITATIONS_GA, INVITATIONS_GA_ACTION, invitationGAData, INVITATIONS_KEYS };

import React, { useState, useEffect } from 'react'
import Modal from './Modal'
import { connect } from 'react-redux'
import { toggleSuccessOtpModal, toggleOtpModal } from '../../../actions/common/modals';
import { otpLength, } from '../../../utils/commonVariables';
import {
    getTwoDigits, handleAuthState
} from "../../../utils/helper";
import get from "lodash/get";
import { changePassword, changePasswordVerifyOtp } from '../../../actions/settings/changePassword';
import { logoutUserLocally } from '../../../actions/login/logout';
import { format, addSeconds } from 'date-fns';

function CommonOtpModal(props) {
    const { toggleSuccessOtpModal, toggleOtpModal, user: { user: { profile, email } },
        token, profileModals } = props;
    const [Otp, setOtp] = useState(""),
        [OtpError, setOtpError] = useState(''),
        [canResendOtp, setCanResendOtp] = useState(false),
        [otpSeconds, setOtpSeconds] = useState(60);

    const handleProceed = async () => {
        await toggleSuccessOtpModal("loggedIn");
        toggleOtpModal(profileModals.otpModal.type);
    }
    const handleSubmit = () => {
        const dataToSend = {
            "email": email,
            ...(profile.phone && !!profile.verified_phone && { "phone": profile.phone }),
            "otp": Otp
        }
        changePasswordVerifyOtp(dataToSend, token)
            .then((val) => {
                if (!!val.data) {
                    handleProceed();
                    setOtpError("");
                } else {
                    const { status, display_message } = val.errors;
                    const errMsg = display_message ? display_message : "Something Went Wrong!";
                    if (!!status) {
                        setOtpError(errMsg);
                    }
                }
            }).catch((err) => {
                setOtpError("Something Went Wrong!")
            })

    }

    const resendOtpMail = () => {
        if (!canResendOtp) return;
        const { email, token } = props;
        let dataToSend = {
            "password": profileModals.otpModal.password
        }
        changePassword(dataToSend, token)
            .then(val => {
                if (!!val && !!val.data) {
                    setOtpSeconds(60)
                    setCanResendOtp(false)
                    setOtpError("");
                    setOtp("");
                } else {
                    const { status, display_message } = val.errors;
                    const errMsg = display_message ? display_message : "Something Went Wrong!"
                    if (!!status) {
                        setOtpError(errMsg);
                    }
                }
            }).catch((err) => {
                setOtpError("Something Went Wrong!");
            })
    }
    const handleClose = async () => {
        await toggleOtpModal("changepassword");
    }

    const handleChangeOtp = (e) => {
        setOtp(e.target.value.slice(0, 6));
        setOtpError("");
    }
    useEffect(() => {
        document.body.style.overflow = "hidden"
        const interval = setInterval(() => {
            if (otpSeconds === 0) {
                clearInterval(interval);
                setCanResendOtp(true);
                setOtpError("");
                return null
            } else {
                setOtpSeconds(otpSeconds - 1);
            }
        }, 1000);
        return () => {
            clearInterval(interval);
            document.body.style.overflow = "auto"
        };
    }, [otpSeconds]);

    return (
        <Modal
            title={"Enter OTP"}
            showHeader={true}
            handleClose={handleClose}
            containerClassName="common-otp-modal "
        >
            <div className="not-center-content">
                <p className="login-form__otp-form--confirm_title">
                    Confirm that this Email and phone number belongs to you: Enter the OTP sent to
                    <span className='color'>{" " + email}</span> {profile.phone && profile.verified_phone && <span className='color'><span style={{ color: "rgba(116, 116, 116, 1)" }}>and</span>  {profile.country_code + " " + profile.phone}</span>}</p>
                <div className="login-form__form__group">
                    <input
                        type="number"
                        placeholder="OTP"
                        autoFocus={true}
                        value={Otp}
                        maxLength={otpLength}
                        onChange={(e) => { handleChangeOtp(e) }}
                        className={`login-form__form__input1 ${OtpError ? "red" : null}`}
                    />
                    {OtpError && <p className="error-text">{OtpError}</p>}
                    {(!OtpError || !canResendOtp) && <p className="login-form__otp-form--time">
                        {format(new Date(1970, 0, 1, 0, 0, otpSeconds), 'mm:ss')}
                    </p>}
                </div>
                <p className="login-form__otp-form--time">Didn’t receive the code? <button
                    className="login-form__otp-form--resend_button" style={{
                        color: canResendOtp ? "rgba(73, 144, 226, 1)" : "rgba(146, 188, 238, 1)"
                    }} disabled={!canResendOtp} onClick={resendOtpMail}>Resend now</button></p>
                <hr className='hr' />
                <button className={`login-form__otp-form--continue_button ${Otp.length < 6 ? "disabled" : ""}`} disabled={Otp.length < 6} onClick={handleSubmit}>Submit</button>
            </div>
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        token: state.login.token,
        user: state.user,
        profileModals: state.modalReducer.profileModals
    }
}

const mapDispatchToProps = {
    toggleSuccessOtpModal,
    toggleOtpModal,
    logoutUserLocally
}

export default connect(mapStateToProps, mapDispatchToProps)(CommonOtpModal) 
import React, { useEffect, useState } from "react";
import PromptFooter from "./PromptFooter";
import { countries, otpLength, phoneMaxLength } from "../../../utils/commonVariables";
import { showToast } from "../../create/utils";
import { format } from 'date-fns';

const PhoneNumber = (props) => {
    const { form: { isUpdating, error, value },
        setForm,
        handleUpdate,
        handleClose,
        token,
        profile,
        getOTPWithDispatch
    } = props;
    const [phoneDetails, setPhoneDetails] = useState({
        country_code: '+91',
        verified_phone: false,
        phone: profile ? profile.phone : "",
        isOTPVerifying: false,
        timerSeconds: 60
    });
    const { country_code, phone, isOTPVerifying, timerSeconds } = phoneDetails;
    const phoneValueLimits = String(phone).length > phoneMaxLimit ? phone.slice(0, phoneMaxLimit) : phone

    const handleSave = () => {
        let data = {
            otp: value,
            phone: phoneValueLimits,
        }
        handleUpdate(data);
    };
    const handleFormUpdate = (e) => {
        if (!e) return false;
        let { name, value } = e.target;
        if (name === "country_code") {
            if (isOTPVerifying) return;
            value = (`+${value}`);
        }
        if (name === "phone") {
            if (isOTPVerifying) return;
            if (isNaN(value) || value.length > phoneMaxLimit) {
                return false;
            }
        }
        if (name === "otp") {
            if (isNaN(value) || value.length > otpLength) {
                return false;
            } else {
                setForm((preForm) => {
                    return { ...preForm, value: value }
                });
                return false;
            }
        }
        setPhoneDetails((preDetails) => {
            return { ...preDetails, [name]: value }
        });

    };

    const handlePhoneVerify = async () => {
        if (isVerifyDisabled && !isOTPVerifying) return;
        let data = {
            profile: {
                country_code,
                phone,
            },
        };
        getOTPWithDispatch(data, token).then((res) => {
            if (!!res.data) {
                setPhoneDetails((preDetails) => {
                    return { ...preDetails, isOTPVerifying: true, timerSeconds: 60 }
                });
            } else {
                const errorMsg = res && res.errors && res.errors.display_message;
                if (errorMsg) {
                    showToast("inviteSuccess", errorMsg)
                }
                setPhoneDetails((preDetails) => {
                    return { ...preDetails, isOTPVerifying: false }
                });
            }
        });

    }

    useEffect(() => {
        if (isOTPVerifying) {
            const timerInterval = setInterval(() => {
                setPhoneDetails((pre) => {
                    if (pre.timerSeconds === 0) {
                        clearInterval(timerInterval);
                        return pre;
                    }
                    return { ...pre, timerSeconds: pre.timerSeconds - 1 };
                });
            }, 1000);

            // Cleanup function to clear the interval when the component unmounts or dependencies change
            return () => clearInterval(timerInterval);
        }
    }, [isOTPVerifying]);

    const isIndianCode = country_code === "+91";
    const phoneMaxLimit = isIndianCode ? 10 : phoneMaxLength;
    const isVerifyDisabled = !country_code.length || String(phone).length < 6 || (isIndianCode && String(phone).length !== 10) || isOTPVerifying;
    return (
        <>
            <div className="profile-prompt__content">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '8px' }}>
                    <label className="profile-prompt__content--label">For optimal experience and to create collab, polls, surveys and quizzes we need to verify your phone. Please share the detail for verification.</label>
                </div>
                <p className="profile-prompt__content--label">
                    <b>Phone Number</b>
                </p>
                <>
                    <div style={{ display: 'flex', marginTop: '8px', gap: '16px' }}>
                        <div style={{ display: 'flex' }}>
                            <select className={`input__select select-country profile-prompt__content--label ${isOTPVerifying ? 'disabled' : ''}`} value={country_code} onChange={handleFormUpdate} name="country_code" disabled={isOTPVerifying}>
                                <option value={country_code}>{country_code}</option>
                                {countries.map((_, index) => {
                                    return <option key={`calling_code-${index}`} value={_.calling_code}>
                                        {_.country} {_.emoji} (+{_.calling_code})
                                    </option>
                                })}
                            </select>
                            <input type="number" value={phoneValueLimits} maxLength={phoneMaxLimit} max={phoneMaxLimit} name="phone" onChange={handleFormUpdate} className={`form-input ${isOTPVerifying ? 'disabled' : ''}`} disabled={isOTPVerifying} />
                        </div>
                        {!isOTPVerifying ? <button
                            className={`button button-link ${isVerifyDisabled ? 'disabled' : ''}`}
                            onClick={handlePhoneVerify}
                            disabled={isVerifyDisabled}
                        >Verify Phone
                        </button> : null}
                        {isOTPVerifying ?
                            <>
                                <input type="number" value={value} name="otp" max={otpLength} maxLength={otpLength} onChange={handleFormUpdate} className="form-input input__otp" placeholder="OTP" />

                                <p className="input__otp--timer">{format(new Date(1970, 0, 1, 0, 0, timerSeconds), 'mm:ss')} </p>
                                <button
                                    className={`button button-link ${timerSeconds !== 0 ? 'disabled' : ''}`}
                                    onClick={handlePhoneVerify}
                                    disabled={timerSeconds !== 0}
                                >Resend
                                </button>
                            </> : null
                        }
                    </div>
                </>

            </div>
            <PromptFooter
                value={value && value.length === otpLength}
                error={error}
                isUpdating={isUpdating}
                handleClose={handleClose}
                handleSave={handleSave}
            />
        </>
    )
}

export default PhoneNumber;